import React, { useEffect, useState } from 'react';
import MODAL_STATES from 'shared/constants/MODAL_STATES';
import PAGES from 'shared/constants/PAGES';
import attachmentsSource from 'shared/sources/attachments';
import landingsPersonalitiesSources from 'shared/sources/landings/landingsPersonalities';
import { ModalFunctions } from '../../../../../interfaces/modal';
import { IAttachmentTypes } from '../../../landings/types';
import withLandingData from '../../helpers/withLandingData';
import PersonsCreate from './PersonsCreate';
import PersonsUpdate from './PersonsUpdate';

interface ModalProps {
  closeModal: ModalFunctions['closeModal'];
  modalState?: string;
  showDeleteModal: () => void;
  data: any;
  onChangeLng: () => void;
  updateList: (data: any) => any;
  onUpdate: (data: any) => any;
  lng: string;
}

const PersonsModal: React.FC<ModalProps> = (props) => {
  const [attachmentTypes, setAttachmentTypes] = useState<IAttachmentTypes>({} as IAttachmentTypes);
  useEffect(() => {
    attachmentsSource.getAttachmentsTypes('persons').then((response) => {
      setAttachmentTypes(response);
    });
  }, []);

  const { data, onChangeLng, onUpdate, closeModal, showDeleteModal, updateList, lng, modalState } = props;

  if (modalState === MODAL_STATES.CREATE) {
    return (
      <PersonsCreate
        attachmentTypes={attachmentTypes}
        onSuccess={updateList}
        onChangeLng={onChangeLng}
        closeModal={closeModal}
      />
    );
  }

  return (
    <PersonsUpdate
      data={data}
      lng={lng}
      attachmentTypes={attachmentTypes}
      onChangeLng={onChangeLng}
      onSuccess={onUpdate}
      showDeleteModal={showDeleteModal}
      closeModal={closeModal}
    />
  );
};

export default withLandingData(PersonsModal, {
  translation: 'personality',
  sources: landingsPersonalitiesSources,
  page: PAGES.LANDING_PERSONALITIES,
  emitUpdateTableAfterSave: true,
});
