import { omit } from 'lodash';
import { orderSources } from 'entities/order';
import { salesActionTypes as actionTypes } from 'entities/orders';

export const loadSales = (params = {}) => ({
  type: actionTypes.LOAD_DATA,
  payload: {
    request: {
      url: orderSources.root,
      params: {
        ...omit(params, [
          'date_id',
          'all_event__in',
          'active_event__in',
          'order_updated_at',
          'order_created',
          'event_date',
        ]),
        ...((params.all_event__in || params.active_event__in) && { event_date_id_in: params.date_id }),
        my_sales: false,
      },
    },
  },
});

export const loadSalesMore = () => ({
  type: actionTypes.LOAD_MORE,
});

export const addNewSale = (data: any) => ({
  type: actionTypes.ADD_NEW,
  payload: {
    data,
  },
});
