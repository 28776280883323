export const EVENTS = {
  CHANGE_LANGUAGE: 'CHANGE_LANGUAGE',
  SWITCHER_LANGUAGE_ENABLE: 'SWITCHER_LANGUAGE_ENABLE',
  WIDGET_QUERY_ERROR: 'WIDGET_QUERY_ERROR',
  EVENT_FORM_UPDATE: 'EVENT_FORM_UPDATE',

  TOGGLE_CREATE_PERIODICAL_DATES_MODAL: 'TOGGLE_CREATE_PERIODICAL_DATES_MODAL',
  ADD_PERIODICAL_DATES: 'ADD_PERIODICAL_DATES',

  MODAL_TOGGLE_POPOVER: 'MODAL_TOGGLE_POPOVER',

  GO_NEXT_STEP: 'GO_NEXT_STEP',

  DELETE_ATTACHMENTS: 'DELETE_ATTACHMENTS',
  DELETE_GALLERY_ATTACHMENTS: 'DELETE_GALLERY_ATTACHMENTS',
  EDIT_ATTACHMENTS: 'EDIT_ATTACHMENTS',
  ADD_PICTURE_GALLERY_ATTACHMENTS: 'ADD_PICTURE_GALLERY_ATTACHMENTS',
  SUCCESS_BULK_UPDATED: 'SUCCESS_BULK_UPDATED',
};

export const TABLE_EVENTS = {
  UPDATE_TABLE: 'UPDATE_TABLE',
};

export enum EventCategoryVisibleType {
  Simple = 1,
  Table = 2,
  HockeyTable = 3,
  FootballTable = 4,
}

export const EVENT_CATEGORY_VISIBLE_TYPE_OPTIONS = [
  {
    id: EventCategoryVisibleType.Simple,
    value: EventCategoryVisibleType.Simple,
    name: 'Стандартный',
  },
  {
    id: EventCategoryVisibleType.Table,
    value: EventCategoryVisibleType.Table,
    name: 'Таблица',
  },
  {
    id: EventCategoryVisibleType.HockeyTable,
    value: EventCategoryVisibleType.HockeyTable,
    name: 'Таблица - хоккей',
  },
  {
    id: EventCategoryVisibleType.FootballTable,
    value: EventCategoryVisibleType.FootballTable,
    name: 'Таблица - футбол',
  },
];

export const STEPS = {
  ZERO: 0,
  ONE: 1,
  THREE: 2,
  FOUR: 3,
};

export const EVENTS_MODAL_STEPS = {
  ZERO: 0,
  ONE: 1,
  TWO: 2,
  THREE: 3,
};
