import flow from 'lodash/flow';
import { withRouter } from 'next/router';
import React, { useState, useEffect } from 'react';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import Button from 'components/Button';
import ConfirmModal from 'components/Modals/ConfirmModal';
import { currencySources } from 'entities/currency';
import { openModal } from 'entities/modal';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';
import LOCALES from 'shared/constants/LOCALES';
import MODAL_STATES from 'shared/constants/MODAL_STATES';
import MODALS from 'shared/constants/MODALS';
import { saveTranslationData, editTranslationData } from 'shared/helpers/form';
import useListItem from 'shared/lib/useListItem';
import withCheckIsMobile from 'shared/lib/withCheckIsMobile';
import { ModalFunctions } from '../../../../../interfaces/modal';
import { withTranslation } from '../../../../i18n';
import CurrencyForm from './form';
import { head } from 'lodash';

interface ModalProps {
  closeModal: ModalFunctions['closeModal'];
  openModal: ModalFunctions['openModal'];
  modalState: string;
  modalData: {
    id: string;
  };
  isMobile: boolean;
  t: (value: string, params?: any) => string;
  forceCloseModal: ModalFunctions['forceCloseModal'];
}

interface StateProps {
  data: any;
  t: ModalProps['t'];
  state: {
    isLoading: boolean;
    isFail: boolean;
  };
}

const CurrencyModal: React.FC<ModalProps> = (props) => {
  const [state, setState] = useState<StateProps>({
    data: null,
    t: props.t,
    state: {
      isLoading: Boolean(props.modalData.id),
      isFail: false,
    },
  });
  const { modalState, isMobile, closeModal, forceCloseModal, t } = props;

  const { emitListReload, get, remove } = useListItem({ source: currencySources });

  const loadData = (lng?: string) => {
    const {
      modalData: { id },
    } = props;

    if (id) {
      get(id, lng)
        .then((data) => {
          setState((prevState) => ({
            ...prevState,
            data: {
              id,
              ...data,
            },
            state: {
              isLoading: false,
              isFail: false,
            },
          }));
          return data;
        })
        .catch(() => {
          setState((prevState) => ({
            ...prevState,
            state: {
              ...prevState.state,
              isFail: true,
            },
          }));
        });

      props.openModal(
        MODALS.REFERENCE_CURRENCY,
        {
          ...props.modalData,
          modalData: {
            mobilePadding: '0',
            fullSize: window.innerWidth < BREAKPOINTS.MD_NUMBER,
          },
        },
        MODAL_STATES.EDIT,
      );
    }
  };
  useEffect(() => {
    loadData();
  }, []);

  const onChangeLng = (lng) => {
    loadData(lng);
  };

  const createCurrency = (data) =>
    saveTranslationData({
      data: editTranslationData(data, (item) => ({
        ...item,
      })),
      create: currencySources.root,
      update: currencySources.detail,
    }).then((translationData) => {
      if (head(translationData.error?.response?.data?.error) === 'Error on create instance.') {
        return NotificationManager.error('Валюта с таким банковским кодом уже существует', 'Ошибка');
      }

      emitListReload();
      return translationData;
    });

  const editCurrency = (data) =>
    saveTranslationData({
      data: editTranslationData(data, (item) => ({
        id: item.id,
        name: item.name,
        cover: item.cover,
        char_code: item.char_code,
        icon: item.icon,
        rate: item.rate,
      })),
      update: currencySources.detail,
    }).then((translationData) => {
      emitListReload();

      return translationData;
    });

  const openEditForm = () => {
    props.openModal(MODALS.REFERENCE_CURRENCY, props.modalData, MODAL_STATES.EDIT);
  };

  const handleDelete = () => {
    const { id } = props.modalData;
    return remove({ id }).then((response) => {
      props.forceCloseModal();

      if (response.error) {
        return NotificationManager.error(t('references:currency:errors:description'));
      }

      emitListReload();

      return response;
    });
  };

  const openDeleteModal = () => {
    props.openModal(
      MODALS.REFERENCE_CURRENCY,
      {
        ...props.modalData,
        modalData: {
          fullSize: false,
          mobilePadding: null,
        },
        CloseButton: null,
      },
      MODAL_STATES.DELETE,
    );
  };

  const loadingState = state.state;

  switch (modalState) {
    case MODAL_STATES.CREATE: {
      return (
        <CurrencyForm
          onSubmit={createCurrency}
          onSuccess={forceCloseModal}
          key="create"
          title="references:currency.create_title"
          isMobile={isMobile}
          state={loadingState}
          stashLanguages
          t={t}
        >
          {isMobile && (
            <Button transparent onClick={closeModal}>
              {t('forms:cancel')}
            </Button>
          )}
          <Button>{t('forms:create')}</Button>
        </CurrencyForm>
      );
    }

    case MODAL_STATES.DELETE:
      return (
        <ConfirmModal
          data={{
            title: t('references:currency.delete_title'),
            text: t('references:currency.delete_text'),
            onSubmit: handleDelete,
            onReject: openEditForm,
          }}
          t={t}
        />
      );

    default: {
      const { data } = state;

      return (
        <CurrencyForm
          isEdit
          key="edit"
          title="references:currency.edit_title"
          onSubmit={editCurrency}
          onSuccess={forceCloseModal}
          initialValues={data || {}}
          isMobile={isMobile}
          onChangeLng={onChangeLng}
          state={loadingState}
          t={t}
        >
          <>
            <Button>{t('forms:save')}</Button>
            <Button danger onClick={openDeleteModal} type="button">
              {t('forms:delete')}
            </Button>
          </>
        </CurrencyForm>
      );
    }
  }
};

const mapStateToProps = (state) => ({
  modalState: state.modal.state,
  modalData: state.modal.data,
});

const mapDispatchToProps = {
  openModal,
};

const withHoC = flow([
  withRouter,
  withCheckIsMobile,
  withTranslation([LOCALES.FORMS, LOCALES.REFERENCES]),
  connect(mapStateToProps, mapDispatchToProps),
]);

export default withHoC(CurrencyModal);
