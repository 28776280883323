import api from 'shared/services/api';
import { currencySources } from 'entities/currency';

interface Currency {
  // eslint-disable-next-line camelcase
  char_code: string;
  icon: string;
  id: number;
  name: string;
  rate: number;
}

export const getCurrency = async () => {
  const response = await api.get(currencySources.root);
  const { results }: { results: Currency[] } = response.data.data;

  return {
    results,
    options: results.map((currency) => ({
      value: currency.id,
      name: currency.name,
      label: currency.name,
      data: currency,
    })),
  };
};

export interface ICurrencyOption {
  label: string;
  name: string;
  value: number;
  data: Currency;
}

export const getCharCode = (currencies: ICurrencyOption[], selectedCurrencyId: number) =>
  currencies.find((currency) => currency.value === selectedCurrencyId).data.char_code;
export const getCurrencyByCharCode = (currencies: ICurrencyOption[], charCode: string) =>
  currencies.find((currency) => currency.data.char_code === charCode);
export const getCurrencyById = (currencies: ICurrencyOption[], id: number) =>
  currencies.find((currency) => currency.data.id === id);
export const getLanguageCurrency = (currentData: any) => {
  if (!currentData.currencies) {
    return {};
  }

  const obj = currentData.currencies.reduce((acc, curr) => {
    const current = curr.value ? curr.value.toLowerCase() : curr.toLowerCase();

    if (currentData.language_currency[current]) {
      acc[current] = currentData.language_currency[current];
    }

    return acc;
  }, {});

  return {
    language_currency: obj,
  };
};
export const availableCurrencyLanguages = (formValues) =>
  formValues.languages
    ? formValues.languages.reduce((acc, lng) => {
        const restrictedLanguages = Object.keys(formValues.language_currency).reduce((arr, curr) => {
          const values = formValues.language_currency[curr];
          if (values) {
            values.forEach((val) => {
              arr.push(val.value);
            });
          }
          return arr;
        }, []);

        if (!restrictedLanguages.includes(lng.value)) {
          acc.push(lng);
        }
        return acc;
      }, [])
    : [];
