import React from 'react';
import { NotificationManager } from 'react-notifications';
import { IEventAttachmentTypes } from 'shared/api/market/events';
import { LANGUAGES } from 'shared/constants/LANGUAGES';
import { createLandingAttachments } from 'shared/sources/attachments';
import landingEventSources from 'shared/sources/landings/landingsEvents';
import { getLandingEventAttachments } from '../lib/event-update';
import EventsForm from './EventsForm';

interface EventsCreateProps {
  lng: string;
  closeModal: () => void;
  onChangeLng: () => void;
  setFormData: (data: any, tab: number) => void;
  attachmentTypes: IEventAttachmentTypes;
}

const EventsCreate: React.FC<EventsCreateProps> = (props) => {
  const { onChangeLng, closeModal, lng, setFormData, attachmentTypes } = props;
  const tab = 2;

  const handleCreate = async (formData) => {
    const { ru: eventData } = formData;

    const createdEvent = await landingEventSources.createEvent({
      data: eventData,
    });

    const attachments = getLandingEventAttachments({
      data: createdEvent,
      formValue: formData,
      attachmentTypes,
    });

    if (attachments?.createAttachment) {
      await createLandingAttachments({ ...attachments.createAttachment, page: 'events' });
    }

    await landingEventSources.createTranslation(createdEvent.id, {
      ...eventData,
      language_code: LANGUAGES.RU,
    });

    const groupsResults = await landingEventSources
      .createContentGroups(
        createdEvent.id,
        eventData.contentGroups.map((i) => i.value),
      )
      .catch((err) => {
        return {
          ...err,
          error: {
            response: {
              data: {
                error: ['Мероприятие с данными тегами уже есть.'],
              },
            },
          },
        };
      });

    if (groupsResults.error) {
      return groupsResults;
    }

    if (eventData.qna) {
      await landingEventSources.createQnA(
        createdEvent.id,
        eventData.qna.map((i) => i.value),
      );
    }

    const newEvent = await landingEventSources.getEvent(createdEvent.id);

    setFormData(newEvent, tab);

    return newEvent;
  };

  return (
    <EventsForm
      key="create"
      title="landings:events.create_title"
      lng={lng}
      onSubmit={handleCreate}
      closeModal={closeModal}
      initialValues={{
        initialQna: [],
        allow_indexing: true,
        item_create: true,
      }}
      onChangeLng={onChangeLng}
    />
  );
};

export default EventsCreate;
