import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import head from 'lodash/head';
import orderBy from 'lodash/orderBy';
import values from 'lodash/values';
import { ICreateAnnouncementsParams, ISecondStateFormData } from 'entities/announcement/types';
import { AnnouncementsVisibleScope } from '../constants/ANNOUNCEMENT';
import { seatsStringToArray } from './seats';
import { orderTickets } from './tickets';

interface PrepareAnnouncementsDataProps {
  announcements: Partial<ISecondStateFormData>[];
  eventId: string | number;
  seatsIds: any;
  currency?: any;
}

const prepareAnnouncementsData = ({
  announcements,
  eventId,
  seatsIds,
  currency,
}: PrepareAnnouncementsDataProps): ICreateAnnouncementsParams[] =>
  announcements.map((announcement) => {
    const announcementCount = announcement[announcement.category ? 'count_categories' : 'count'];
    const announcementCurrency = get(announcement, 'currency.value', currency);
    const seats = announcement.seat ? seatsStringToArray(announcement.seat) : [0];
    const emptyAnnouncementType = announcement.is_certificate ? undefined : null;
    const emptyTicketType = announcement.is_certificate ? undefined : null;
    const isCategoryAnnouncement = announcement.category;

    const data = {
      id: announcement.id,
      event_id: eventId,
      announcement_type: announcement.announcement_type
        ? Number(announcement.announcement_type)
        : emptyAnnouncementType,
      expansion: announcement.expansion && announcement.expansion.map((i) => i.value),
      currency: announcementCurrency,
      tickets: seats.map((seat) => {
        const count = announcementCount ? Number(announcementCount) : 1;
        const seatData: any = {
          count,
          price: announcement.price,
          total_price: announcement.total_price,
          purchase_price: announcement.purchase_price,
          ticket_type: announcement.ticket_type ? Number(announcement.ticket_type) : emptyTicketType,
          personal: announcement.personal || false,
          non_returnable: announcement.non_returnable || false,
        };

        if (announcement.sector && announcement.sector.value) {
          seatData.sector = announcement.sector.value;
        }

        if (announcement.category && announcement.category.value) {
          seatData.category = announcement.category.value;
        }

        if (announcement.row && announcement.row.value) {
          seatData.row = announcement.row.value;
        }

        if (announcement.row && announcement.seat) {
          seatData.seat = seatsIds[announcement.row.value][seat];
        }

        return seatData;
      }),
    };

    if (announcement.provider) {
      data.provider = announcement.provider.value;
    }
    data.view_scope_type = Number(announcement.view_scope_type || AnnouncementsVisibleScope.SEATS);

    if (isCategoryAnnouncement) {
      data.view_scope_type = AnnouncementsVisibleScope.CATEGORY;
    }

    return data;
  });

const groupAnnouncementTickets = (tickets) => {
  const firstTicket: any = head(tickets);
  const isStanding = !firstTicket.seat;
  let groupedTickets = null;

  if (tickets) {
    if (isStanding) {
      groupedTickets = values(
        groupBy(tickets, (t) => {
          const order = t.order ? `${t.order.destination}_${t.order.b2b}` : 0;
          return `${t.isPreorderTicket}_${order}_${t.status}`;
        }),
      );
      groupedTickets = orderBy(groupedTickets, (group) => orderTickets(group));
    } else {
      ({ groupedTickets } = tickets.reduce(
        (acc, ticket) => {
          const orderIsDiff =
            (ticket.order && acc.order && ticket.order.destination !== acc.order.destination) ||
            (ticket.order && acc.order && ticket.order.b2b !== acc.order.b2b) ||
            !!ticket.order !== !!acc.order;

          if (ticket.status !== acc.currentStatus || orderIsDiff) {
            acc.index += 1;
            acc.currentStatus = ticket.status;
            acc.order = ticket.order;
          }

          acc.groupedTickets[acc.index] = acc.groupedTickets[acc.index] || [];
          acc.groupedTickets[acc.index].push(ticket);

          return acc;
        },
        {
          index: 0,
          currentStatus: firstTicket.status,
          groupedTickets: [],
          order: firstTicket.order,
        },
      ));
      groupedTickets = orderBy(groupedTickets, (group) => parseInt(group[0].seat, 10), ['asc']);
    }
  }

  return groupedTickets;
};

export { prepareAnnouncementsData, groupAnnouncementTickets };
