import { isEmpty } from 'lodash';
import config from 'config/config';
import { ICloningLandingAttachments } from 'pages/landings/types';
import { Language } from '../api/types';
import COOKIES from '../constants/COOKIES';
import { getCookie } from '../lib/cookie';
import api from '../services/api';

export interface IAttachments {
  attachment_type_id: number;
  data: { [key: string]: string };
  language_code: Language;
  title: string;
  info: { is_create: boolean; title?: string; alt?: string }[];
}

const companyId = getCookie(COOKIES.COMPANY);

const routeInfo = `${config.COMMON_API_URL}/admin/v1/${getCookie(COOKIES.COMPANY)}/landings`;

const attachmentsSource = {
  async getAttachmentsTypes(entityType: string) {
    const response = await api.get(`${config.COMMON_API_URL}/admin/v1/attachment_types`, {
      params: {
        entity_type: entityType,
      },
    });

    return response.data.results.reduce((acc, item) => {
      acc[item.code] = item;

      return acc;
    }, {});
  },
};

export default attachmentsSource;

export const createLandingAttachments = async ({
  attachments,
  id,
  page,
}: {
  id: number;
  attachments: IAttachments[];
  page: string;
}) => {
  for (const attachment of attachments) {
    await api.post(`${config.COMMON_API_URL}/admin/v1/${companyId}/landings/${page}/${id}/attachments`, {
      data: attachment.data,
      attachment_type_id: attachment.attachment_type_id,
    });
    if (!isEmpty(attachment?.info)) {
      await api.post(`${routeInfo}/${page}/${id}/attachments/${attachment.attachment_type_id}/info`, {
        ...attachment.info,
        language_code: attachment.language_code,
      });
    }
  }
};

export const editLandingAttachments = async ({
  attachments,
  id,
  page,
}: {
  id: number;
  attachments: IAttachments[];
  page: string;
}) => {
  for (const attachment of attachments) {
    await api.patch(
      `${config.COMMON_API_URL}/admin/v1/${companyId}/landings/${page}/${id}/attachments/${attachment.attachment_type_id}`,
      {
        data: attachment.data,
      },
    );

    if (!isEmpty(attachment.info)) {
      if (attachment.info?.is_create) {
        await api.post(`${routeInfo}/${page}/${id}/attachments/${attachment.attachment_type_id}/info`, {
          ...attachment.info,
          language_code: attachment.language_code,
        });
      } else {
        await api.patch(
          `${routeInfo}/${page}/${id}/attachments/${attachment.attachment_type_id}/info/${attachment.language_code}`,
          attachment.info,
        );
      }
    }
  }
};

export const deleteLandingAttachments = async ({
  attachments,
  id,
  page,
}: {
  id: number;
  attachments: { id: number }[];
  page: string;
}) => {
  const requests = attachments.map(async (attachment) => {
    const response = api.delete(
      `${config.COMMON_API_URL}/admin/v1/${companyId}/landings/${page}/${id}/attachments/${attachment?.id}`,
    );

    return response;
  });

  await Promise.all(requests);
};

export const cloningLandingAttachments = async ({
  attachments,
  id,
  page,
}: {
  id: number;
  attachments: ICloningLandingAttachments[];
  page: string;
}) => {
  for (const attachment of attachments) {
    await api.post(`${config.COMMON_API_URL}/admin/v1/${companyId}/landings/${page}/${id}/attachments`, {
      data: attachment.data,
      attachment_type_id: attachment.attachment_type_id,
    });
    if (!isEmpty(attachment?.info)) {
      for (const info of attachment.info) {
        await api.post(`${routeInfo}/${page}/${id}/attachments/${attachment.attachment_type_id}/info`, {
          alt: info.alt,
          title: info.title,
          language_code: info.language_code,
        });
      }
    }
  }
};
