import { AxiosError, AxiosResponse } from 'axios';
import { createEffect } from 'effector';
import { Router } from 'next/router';
import config from 'config/config';
import api from 'services/api';
import COOKIES from '../../../constants/COOKIES';
import { getCookie } from '../../../lib/cookie';
import { ITag, ITags } from './types';

const companyId = getCookie(COOKIES.COMPANY);

export const tagsRoute = `${config.COMMON_API_URL}/admin/v1/${companyId}/content_groups`;

export const fetchTag = (id: number) => `${tagsRoute}/${id}`;

export const fetchTagsFx = createEffect(async (query: Router['query'] = {}): Promise<ITags> => {
  const response = await api.get(`${tagsRoute}`, {
    params: {
      ...query,
      limit: 5000,
    },
  });

  return response.data;
});

export const fetchTagFx = createEffect(async ({ id }: { id: number }): Promise<ITag> => {
  const response = await api.get(`${tagsRoute}/${id}`);

  return response.data;
});

export const updateTagFx = createEffect(
  async ({ id, title }: { id: number; title: string }): Promise<ITag> => {
    const response = await api.patch(`${tagsRoute}/${id}`, { title });

    return response.data;
  },
);

export const createTagFx = createEffect<string, AxiosResponse<ITag>, AxiosError>(async (title: string) => {
  const response = await api.post(`${tagsRoute}`, { title, company_id: companyId });

  return response.data;
});

export const mergeTagFx = createEffect(
  async ({ id, content_groups }: { id: number; content_groups: number[] }): Promise<ITag> => {
    const response = await api.post(`${tagsRoute}/${id}/merge`, { content_groups });

    return response.data;
  },
);
