import React from 'react';
import routes from 'shared/helpers/routes';
import Link from '../Link';
import SplittedNumber from '../SplittedNumber';

interface Props {
  order: any;
}

const TicketOrderStatusLink: React.FC<Props> = ({ order }) => (
  <Link
    target="_blank"
    rel="noopener noreferrer"
    href={`${order && order.destination === 1 ? routes.purchases : routes.sales}?id=${
      order && order.id ? order.id : order.order_id
    }`}
  >
    <SplittedNumber>{order.id || order.order_id}</SplittedNumber>
  </Link>
);

export default TicketOrderStatusLink;
