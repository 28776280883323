import { LANGUAGES } from '../constants/LANGUAGES';
import { IAttachments } from '../sources/attachments';
import {
  createAttachments,
  deleteAttachments,
  filterCreateAttachments,
  filterDeleteAttachments,
  filterUpdateAttachments,
  updateAttachments,
} from './attachments';

export const getAttachments = <
  TData extends { id: number },
  TFormValue extends { lng: string },
  TAttachmentTypes,
>({
  data,
  formValue,
  attachmentTypes,
}: {
  data: TData;
  formValue: TFormValue;
  attachmentTypes: TAttachmentTypes;
}) => {
  let createAttachment: { id: number; attachments: IAttachments[] } | undefined;
  let updateAttachment: { id: number; attachments: IAttachments[] } | undefined;
  let deleteAttachment: { id: number; attachments: { id: number }[] } | undefined;

  if (filterUpdateAttachments(data, formValue)) {
    updateAttachment = {
      id: data.id,
      attachments: updateAttachments(data, formValue),
    };
  }

  if (formValue.lng === LANGUAGES.RU) {
    if (filterCreateAttachments(data, formValue)) {
      createAttachment = {
        id: data.id,
        attachments: createAttachments(data, formValue, attachmentTypes!),
      };
    }

    if (filterDeleteAttachments(data, formValue)) {
      deleteAttachment = {
        id: data.id,
        attachments: deleteAttachments(data, formValue),
      };
    }
  }

  return { createAttachment, updateAttachment, deleteAttachment };
};
