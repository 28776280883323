import React from 'react';
import { Field } from 'react-final-form';
import { LANGUAGES } from 'shared/constants/LANGUAGES';
import { findTranslation } from 'shared/helpers/translations';
import Form from '../../../Form';
import { Input, Checkbox } from '../../../FormControls';
import ConstructorFooter from './ConstructorFooter';
import Styled from './styles';

interface StepProps {
  onSubmit: (data: any) => any;
  updateData: any;
  goBack: () => void;
  data: any;
  lng: string;
}

const SectionsStep: React.FC<StepProps> = ({ onSubmit, updateData, data, goBack, lng }) => {
  return (
    <Styled.Container withTitle>
      <Styled.Header>
        <Styled.Title>Выберите Разделы</Styled.Title>
      </Styled.Header>
      <Styled.Content>
        <Form
          onSubmit={onSubmit}
          initialValues={data}
          render={({ handleSubmit, form, values }) => (
            <form onSubmit={handleSubmit}>
              <Styled.FormRow>
                <Styled.FormDescription>
                  Разделы в меню сайта
                  <Styled.FormSmallDescription>Настройка разделов</Styled.FormSmallDescription>
                </Styled.FormDescription>
                <Styled.FormControls>
                  {lng !== LANGUAGES.RU ? (
                    <>
                      <Styled.FormControl>
                        <Field name="ui_config_show_media_name">
                          {({ input, meta }) => (
                            <Input placeholder="Название раздела Медиа" meta={meta} {...input} />
                          )}
                        </Field>
                      </Styled.FormControl>
                      <Styled.FormControl>
                        <Field name="ui_config_show_news_name">
                          {({ input, meta }) => (
                            <Input placeholder="Название раздела Новости" meta={meta} {...input} />
                          )}
                        </Field>
                      </Styled.FormControl>
                      <Styled.FormControl>
                        <Field name="ui_config_show_afisha_name">
                          {({ input, meta }) => (
                            <Input placeholder="Название раздела Афиша" meta={meta} {...input} />
                          )}
                        </Field>
                      </Styled.FormControl>
                      <Styled.FormControl>
                        <Field name="ui_config_show_places_name">
                          {({ input, meta }) => (
                            <Input placeholder="Название раздела о Площадке" meta={meta} {...input} />
                          )}
                        </Field>
                      </Styled.FormControl>
                      <Styled.FormControl>
                        <Field name="ui_config_show_persons_name">
                          {({ input, meta }) => (
                            <Input placeholder="Название раздела Персоналии" meta={meta} {...input} />
                          )}
                        </Field>
                      </Styled.FormControl>
                      <Styled.FormControl>
                        <Field name="ui_config_show_additional_name">
                          {({ input, meta }) => (
                            <Input placeholder="Название доп раздела" meta={meta} {...input} />
                          )}
                        </Field>
                      </Styled.FormControl>
                      <Styled.FormControl>
                        <Field name="ui_config_show_additional_name_second">
                          {({ input, meta }) => (
                            <Input placeholder="Название второго доп раздела" meta={meta} {...input} />
                          )}
                        </Field>
                      </Styled.FormControl>
                      <Styled.FormControl>
                        <Field name="ui_config_custom_pages_group_name">
                          {({ input, meta }) => <Input placeholder="Название меню" meta={meta} {...input} />}
                        </Field>
                      </Styled.FormControl>
                    </>
                  ) : (
                    <>
                      <Styled.FormControl>
                        <Field name="ui_config_show_media" type="checkbox">
                          {({ input, meta }) => (
                            <Checkbox variant="round" meta={meta} {...input}>
                              Раздел Медиа
                            </Checkbox>
                          )}
                        </Field>
                      </Styled.FormControl>
                      {values?.ui_config_show_media && (
                        <Styled.FormControl child>
                          <Field name="ui_config_show_media_name">
                            {({ input, meta }) => (
                              <Input placeholder="Название раздела Медиа" meta={meta} {...input} />
                            )}
                          </Field>
                        </Styled.FormControl>
                      )}
                      <Styled.FormControl>
                        <Field name="ui_config_show_news" type="checkbox">
                          {({ input, meta }) => (
                            <Checkbox variant="round" meta={meta} {...input}>
                              Раздел Новости
                            </Checkbox>
                          )}
                        </Field>
                      </Styled.FormControl>
                      {values.ui_config_show_news && (
                        <Styled.FormControl child>
                          <Field name="ui_config_show_news_name">
                            {({ input, meta }) => (
                              <Input placeholder="Название раздела Новости" meta={meta} {...input} />
                            )}
                          </Field>
                        </Styled.FormControl>
                      )}

                      <Styled.FormControl>
                        <Field name="ui_config_show_afisha" type="checkbox">
                          {({ input, meta }) => (
                            <Checkbox variant="round" meta={meta} {...input}>
                              Раздел Афиша
                            </Checkbox>
                          )}
                        </Field>
                      </Styled.FormControl>
                      {values.ui_config_show_afisha && (
                        <Styled.FormControl child>
                          <Field name="ui_config_show_afisha_name">
                            {({ input, meta }) => (
                              <Input placeholder="Название раздела Афиша" meta={meta} {...input} />
                            )}
                          </Field>
                        </Styled.FormControl>
                      )}

                      <Styled.FormControl>
                        <Field name="ui_config_show_places" type="checkbox">
                          {({ input, meta }) => (
                            <Checkbox variant="round" meta={meta} {...input}>
                              Раздел О площадке
                            </Checkbox>
                          )}
                        </Field>
                      </Styled.FormControl>
                      {values.ui_config_show_places && (
                        <Styled.FormControl child>
                          <Field name="ui_config_show_places_name">
                            {({ input, meta }) => (
                              <Input placeholder="Название раздела о Площадке" meta={meta} {...input} />
                            )}
                          </Field>
                        </Styled.FormControl>
                      )}

                      <Styled.FormControl>
                        <Field name="ui_config_show_persons" type="checkbox">
                          {({ input, meta }) => (
                            <Checkbox variant="round" meta={meta} {...input}>
                              Раздел Персоналии
                            </Checkbox>
                          )}
                        </Field>
                      </Styled.FormControl>
                      {values.ui_config_show_persons && (
                        <Styled.FormControl child>
                          <Field name="ui_config_show_persons_name">
                            {({ input, meta }) => (
                              <Input placeholder="Название раздела Персоналии" meta={meta} {...input} />
                            )}
                          </Field>
                        </Styled.FormControl>
                      )}

                      <Styled.FormControl>
                        <Field name="ui_config_show_additional" type="checkbox">
                          {({ input, meta }) => (
                            <Checkbox variant="round" meta={meta} {...input}>
                              Дополнительный раздел
                            </Checkbox>
                          )}
                        </Field>
                      </Styled.FormControl>
                      {values.ui_config_show_additional && (
                        <>
                          <Styled.FormControl child>
                            <Field name="ui_config_show_additional_link">
                              {({ input, meta }) => (
                                <Input label="Ссылка на доп раздел" meta={meta} {...input} />
                              )}
                            </Field>
                          </Styled.FormControl>
                          <Styled.FormControl>
                            <Field name="ui_config_show_additional_name">
                              {({ input, meta }) => (
                                <Input placeholder="Название доп раздела" meta={meta} {...input} />
                              )}
                            </Field>
                          </Styled.FormControl>
                          <Styled.FormControl child>
                            <Field name="ui_config_show_additional_link_second">
                              {({ input, meta }) => (
                                <Input label="Ссылка на второй доп раздел" meta={meta} {...input} />
                              )}
                            </Field>
                          </Styled.FormControl>
                          <Styled.FormControl>
                            <Field name="ui_config_show_additional_name_second">
                              {({ input, meta }) => (
                                <Input placeholder="Название второго доп раздела" meta={meta} {...input} />
                              )}
                            </Field>
                          </Styled.FormControl>
                        </>
                      )}
                    </>
                  )}
                </Styled.FormControls>
              </Styled.FormRow>

              {lng === LANGUAGES.RU && (
                <>
                  <Styled.FormRow>
                    <Styled.FormDescription>Поиск по сайту</Styled.FormDescription>
                    <Styled.FormControls>
                      <Styled.FormControl>
                        <Field name="ui_config_search" type="checkbox">
                          {({ input, meta }) => (
                            <Checkbox variant="round" meta={meta} {...input}>
                              Иконка поиска
                            </Checkbox>
                          )}
                        </Field>
                        <Styled.FormSmallDescription>
                          Отображение иконки поиска “лупа” в меню сайта
                        </Styled.FormSmallDescription>
                      </Styled.FormControl>
                    </Styled.FormControls>
                  </Styled.FormRow>
                  <Styled.FormRow>
                    <Styled.FormDescription>Группировка кастомных страниц</Styled.FormDescription>
                    <Styled.FormControls>
                      <Styled.FormControl>
                        <Field name="ui_config_custom_pages_grouping" type="checkbox">
                          {({ input, meta }) => (
                            <Checkbox variant="round" meta={meta} {...input}>
                              Сгруппировать кастомные страницы
                            </Checkbox>
                          )}
                        </Field>
                        <Styled.FormSmallDescription>
                          Группировка кастомных страниц в меню сайта
                        </Styled.FormSmallDescription>
                      </Styled.FormControl>
                      {values.ui_config_custom_pages_grouping && (
                        <Styled.FormControl child>
                          <Field name="ui_config_custom_pages_group_name">
                            {({ input, meta }) => <Input label="Название меню" meta={meta} {...input} />}
                          </Field>
                        </Styled.FormControl>
                      )}
                    </Styled.FormControls>
                  </Styled.FormRow>
                </>
              )}

              <ConstructorFooter
                goBack={lng === LANGUAGES.RU && goBack}
                form={form}
                updateData={updateData}
              />
            </form>
          )}
        />
      </Styled.Content>
    </Styled.Container>
  );
};

export default SectionsStep;
