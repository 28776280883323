import React from 'react';
import { NotificationManager } from 'react-notifications';
import COOKIES from 'shared/constants/COOKIES';
import { LANGUAGES } from 'shared/constants/LANGUAGES';
import { getCookie } from 'shared/lib/cookie';
import { getAttachments } from 'shared/lib/getAttachments';
import api from 'shared/services/api';
import { createLandingAttachments } from 'shared/sources/attachments';
import landingsPlaygroundsSources from 'shared/sources/landings/landingsPlaygrounds';
import { IAttachmentTypes } from '../../types';
import PlacesForm from './PlacesForm';

interface PlacesCreateProps {
  onSuccess: (data: any) => any;
  closeModal: () => void;
  onChangeLng: () => void;
  attachmentTypes: IAttachmentTypes;
}

const PlacesCreate: React.FC<PlacesCreateProps> = (props) => {
  const { onSuccess, closeModal, onChangeLng, attachmentTypes } = props;

  const handleCreate = async (formData) => {
    const { ru: placeData } = formData;
    const companyId = getCookie(COOKIES.COMPANY);

    const response = await api.post(landingsPlaygroundsSources.root, {
      place_id: placeData.place.value,
      old_id: placeData.place.value,
      slug: '',
      company_id: companyId,
    });
    const attachments = getAttachments({
      data: response.data,
      formValue: formData,
      attachmentTypes,
    });

    if (attachments?.createAttachment) {
      await createLandingAttachments({ ...attachments.createAttachment, page: 'places' });
    }

    const translations = await landingsPlaygroundsSources.createTranslation(response.data.id, {
      ...placeData,
      language_code: LANGUAGES.RU,
    });

    await landingsPlaygroundsSources
      .createContentGroups(
        response.data.id,
        placeData.contentGroups.map((i) => i.value),
      )
      .catch((error) => {
        const errMessage = error.response?.data?.detail;
        if (errMessage && errMessage.includes('Place with content group')) {
          NotificationManager.error('Данная площадка с таким тегом существует', 'Ошибка');
        }
        return error;
      });

    await landingsPlaygroundsSources.createQnA(
      response.data.id,
      (placeData.qna || []).map((i) => i.value),
    );

    return translations;
  };

  return (
    <PlacesForm
      title="Новая площадка"
      onSubmit={handleCreate}
      onSuccess={onSuccess}
      closeModal={closeModal}
      onChangeLng={onChangeLng}
      initialValues={{
        item_create: true,
      }}
    />
  );
};

export default PlacesCreate;
