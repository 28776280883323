import { AxiosRequestConfig } from 'axios';
import head from 'lodash/head';
import React from 'react';
import { Field } from 'react-final-form';
import api from 'services/api';
import { orderSources } from 'entities/order';
import { PRICE_CHANGE_TYPES } from 'shared/constants/ORDER';
import { isSafari } from 'shared/helpers/devices';
import { calcNewPrice, getMaxPrice, getDiscountDestination } from 'shared/helpers/order';
import {
  maxNumberValue,
  minNumberValue,
  composeValidators,
  validSpecialChars,
  required,
} from 'shared/helpers/validations';
import WhenFieldChanges from 'shared/lib/FormWhenFieldChanges';
import { ModalFunctions } from '../../../../interfaces/modal';
import Button from '../../Button';
import Form from '../../Form';
import { DiscountInput } from '../../FormControls';
import Modal from '../../Modal';
import SplittedNumber from '../../SplittedNumber';
import Styled from './styles';

// TODO: Should handle this on the BE
const currencySymbols = {
  RUB: '₽',
  USD: '$',
};

interface ModalProps {
  data: any;
  loadSale: (id: number) => any;
  closeModal: ModalFunctions['closeModal'];
  setDiscount: (id: number, data: any) => any;
}

const OrderDiscountModal: React.FC<ModalProps> = ({
  loadSale,
  closeModal,
  setDiscount,
  data: { id, destination, tickets, currency },
}) => {
  const firstTicket = head<any>(tickets);
  const defaultDestination = getDiscountDestination(firstTicket.price, firstTicket.discount_price);

  const discountParams =
    defaultDestination === destination
      ? {
          ...firstTicket.discount_params,
          destination,
        }
      : null;

  const initialValues = discountParams || {
    destination,
    discount_type: PRICE_CHANGE_TYPES.NUMBER,
  };

  const { price } = firstTicket;
  const discountPrice = firstTicket.discount_price;

  const submitDiscount = (formData) => {
    if (discountPrice && (Number(formData.amount) === 0 || !formData.amount)) {
      return api
        .delete(orderSources.discount(id), {
          data: {
            tickets: tickets.map((ticket: any) => ticket.id),
          },
          notificationHiddenOnError: true,
        } as AxiosRequestConfig)
        .catch(() => {
          if (isSafari()) {
            loadSale(id).then(closeModal);
          }
        });
    }

    return setDiscount(id, {
      amount: Number(formData.amount),
      destination: Number(formData.destination),
      discount_type: Number(formData.discount_type),
      tickets: tickets.map((ticket) => ticket.id),
    }).then(() => loadSale(id).then(closeModal));
  };

  return (
    <Styled.Container>
      <Styled.GlobalStyles />
      <Styled.Title>Изменение цены</Styled.Title>
      <Form
        onSubmit={submitDiscount}
        initialValues={initialValues}
        render={({ handleSubmit, values }) => {
          const max = getMaxPrice({ values, destination, price });

          return (
            <form onSubmit={handleSubmit}>
              <WhenFieldChanges field="discount_type" becomes="10" set="amount" to={0} />
              <WhenFieldChanges field="discount_type" becomes="70" set="amount" to={0} />
              <Field type="hidden" name="destination" component="input" />
              <Field
                name="amount"
                validate={composeValidators(
                  required,
                  minNumberValue(0),
                  maxNumberValue(max),
                  validSpecialChars(),
                )}
              >
                {({ input, meta }) => (
                  <DiscountInput
                    fixedError
                    input={input}
                    meta={meta}
                    destination={destination}
                    max={max}
                    currency={currencySymbols[currency.char_code]}
                  />
                )}
              </Field>
              <Styled.Prices>
                <Styled.OldPrice>
                  <Styled.PriceTitle>Старая Цена</Styled.PriceTitle>
                  <Styled.Price>
                    <span>
                      <SplittedNumber withSuffix icon={currency.icon}>
                        {price}
                      </SplittedNumber>
                    </span>
                  </Styled.Price>
                </Styled.OldPrice>
                <Styled.NewPrice>
                  <Styled.PriceTitle>Новая Цена</Styled.PriceTitle>
                  <Styled.Price>
                    <span>
                      <SplittedNumber withSuffix icon={currency.icon}>
                        {calcNewPrice({
                          price,
                          discountType: Number(values.discount_type),
                          amount: Number(values.amount),
                          destination: values.destination,
                        }).toFixed()}
                      </SplittedNumber>
                    </span>
                  </Styled.Price>
                </Styled.NewPrice>
              </Styled.Prices>
              <Modal.Footer>
                <Button transparent type="button" onClick={closeModal}>
                  Отмена
                </Button>
                <Button>Изменить цену</Button>
              </Modal.Footer>
            </form>
          );
        }}
      />
    </Styled.Container>
  );
};

export default OrderDiscountModal;
