import compact from 'lodash/compact';
import debounce from 'lodash/debounce';
import flatten from 'lodash/flatten';
import head from 'lodash/head';
import includes from 'lodash/includes';
import { Router, withRouter } from 'next/router';
import React, { Component } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { eventsSources } from 'shared/api/events';
import { getEventsDataForMultiSelect } from 'shared/helpers/events';
import { getAdaptedFilterEvent } from 'shared/helpers/formatters/event';
import { queryFilter } from 'shared/lib/queryFilter';
import api from 'shared/services/api';
import { Spinner } from 'shared/ui/spinner';
import { ModalFunctions } from '../../../../interfaces/modal';
import { IResources } from '../../../../interfaces/resources';
import SearchInputIcon from '../../../../static/icons/search-input.svg';
import Button from '../../Button';
import DataPreview from '../../DataPreview';
import Input from '../../FormControls/Input';
import Modal from '../../Modal';
import ReportsEventsFilterModalListItem from './ReportsEventsFilterModalListItem';
import ReportsEventsFilterModalTag from './ReportsEventsFilterModalTag';
import Styled from './styles';

interface ModalProps {
  router: Router;
  closeModal: ModalFunctions['closeModal'];
}

interface ModalState {
  data: IResources<any>;
  inputValue: string;
  selectedEvents: any[];
  selectedEventsIds: any[];
  selectedDatesIds: any[];
}

class ReportsEventsFilterModal extends Component<ModalProps, ModalState> {
  debouncedLoadData: any;

  constructor(props: ModalProps) {
    super(props);

    this.state = {
      data: {
        data: [],
        meta: {},
        state: {
          isLoading: true,
          isLoadingMore: true,
          isFail: false,
        },
      },
      inputValue: '',
      selectedEvents: [],
      selectedEventsIds: [],
      selectedDatesIds: [],
    };

    this.debouncedLoadData = debounce(this.loadData, 1000);
  }

  componentDidMount() {
    const {
      router: {
        query: { event__in: parentIds, date_id: childIds },
      },
    } = this.props;

    if (parentIds) {
      const selectedEventsIds = parentIds?.split(',').map((item) => Number(item)) || [];
      const selectedDatesIds = childIds?.split(',').map((item) => Number(item)) || [];

      getEventsDataForMultiSelect(selectedEventsIds, childIds)
        .then((response) => {
          const { events } = response;
          this.setState(
            {
              selectedEvents: events,
              selectedEventsIds,
              selectedDatesIds,
            },
            () => {
              this.loadData();
            },
          );
        })
        .catch((err) => new Error(err));
    } else {
      this.loadData();
    }
  }

  handleOnChange = (event: any) => {
    this.setState(
      {
        inputValue: event.target.value,
      },
      () => {
        this.debouncedLoadData();
      },
    );
  };

  handleClick = () => {
    this.props.closeModal();
    queryFilter({
      event__in: this.state.selectedEventsIds.join(','),
      date_id: this.state.selectedDatesIds.join(','),
    });
  };

  selectEvent = (event: any) => {
    const {
      selectedEvents,
      data: { data, state },
    } = this.state;

    this.setState(
      (prevState) => ({
        selectedEvents: [...prevState.selectedEvents, event],
        selectedEventsIds: [...prevState.selectedEventsIds, event.id],
        selectedDatesIds: compact(
          flatten([
            ...prevState.selectedDatesIds,
            event.children && event.children.map((item: any) => item.id),
          ]),
        ),
      }),
      () => {
        if (!state.isLoadingMore && data.length - selectedEvents.length <= 15) {
          this.loadData({ loadMore: true });
        }
      },
    );
  };

  selectEventChildren = (id: number) => {
    this.setState(({ selectedDatesIds: ids }) => ({
      selectedDatesIds: includes(ids, id) ? ids.filter((item) => item !== id) : [...ids, id],
    }));
  };

  unselectEvent = (ids: number[]) => {
    this.setState((prevState) => ({
      selectedEvents: prevState.selectedEvents.filter((item) => item.id !== head(ids)),
      selectedEventsIds: prevState.selectedEventsIds.filter((item) => !includes(ids, item)),
      selectedDatesIds: prevState.selectedDatesIds.filter((item) => !includes(ids, item)),
    }));
  };

  loadMore = () => {
    this.loadData({ loadMore: true });
  };

  loadData = ({ loadMore = false } = {}) => {
    const { data, inputValue } = this.state;

    this.setState(
      ({ data: prevData }) => ({
        data: {
          ...prevData,
          state: {
            isLoading: !loadMore,
            isLoadingMore: loadMore,
            isFail: false,
          },
        },
      }),
      () => {
        api
          .get(eventsSources.root, {
            params: {
              search_string: inputValue,
              ...(loadMore && { page: data.meta.page + 1 }),
            },
          })
          .then((response) => {
            const { results, ...meta } = response.data;

            this.setState(({ data: prevData }) => ({
              data: {
                data: loadMore
                  ? [...prevData.data, ...results]
                  : results.map((event: any) => getAdaptedFilterEvent(event, true)),
                meta,
                state: {
                  isLoading: false,
                  isLoadingMore: false,
                  isFail: false,
                },
              },
            }));
          })
          .catch(() =>
            this.setState({
              data: {
                data: [],
                meta: {},
                state: {
                  isLoading: false,
                  isLoadingMore: false,
                  isFail: true,
                },
              },
            }),
          );
      },
    );
  };

  render() {
    const {
      inputValue,
      selectedEvents,
      selectedEventsIds,
      selectedDatesIds,
      data: { data, meta, state },
    } = this.state;
    const { closeModal } = this.props;
    const { isLoadingMore } = state;

    return (
      <Styled.Container>
        <Styled.GlobalStyles />
        <Styled.Title>
          <Modal.Title>Выбор мероприятий из списка</Modal.Title>
        </Styled.Title>
        {selectedEvents.length > 0 && (
          <Styled.Tags>
            {selectedEvents.map((item) => (
              <ReportsEventsFilterModalTag
                data={item}
                unselectEvent={this.unselectEvent}
                selectedDatesIds={selectedDatesIds}
                selectEventChildren={this.selectEventChildren}
              />
            ))}
          </Styled.Tags>
        )}
        <Input label="Поиск" value={inputValue} icon={<SearchInputIcon />} onChange={this.handleOnChange} />
        <Styled.InfiniteScroll>
          <InfiniteScroll
            next={this.loadMore}
            loader={
              isLoadingMore && (
                <Styled.LoadingMore>
                  <Spinner />
                </Styled.LoadingMore>
              )
            }
            dataLength={data.length}
            hasMore={meta.page !== meta.last_page}
            scrollableTarget="reports_events_filter_modal-list"
          >
            <Styled.List
              id="reports_events_filter_modal-list"
              centered={state.isLoading || data.length === 0}
            >
              <DataPreview length={data.length} state={state}>
                {data
                  .filter((item) => !includes(selectedEventsIds, item.id))
                  .map((item) => (
                    <ReportsEventsFilterModalListItem data={item} selectEvent={this.selectEvent} />
                  ))}
              </DataPreview>
            </Styled.List>
          </InfiniteScroll>
        </Styled.InfiniteScroll>
        <Styled.Footer>
          <Modal.Footer>
            <Button type="button" transparent onClick={closeModal}>
              Отмена
            </Button>
            <Button type="button" onClick={this.handleClick}>
              {`Выбрать ${selectedEvents.length !== 0 ? `(${selectedEvents.length})` : ''}`}
            </Button>
          </Modal.Footer>
        </Styled.Footer>
      </Styled.Container>
    );
  }
}

export default withRouter(ReportsEventsFilterModal);
