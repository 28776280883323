import config from "config/config";

export const invoicesSources = {
  root: 'invoice',
  create: 'invoice/create',
  rejectReasonsList: `${config.TICKETS_API_URL}/admin/v1/invoices/reject-reasons-list`,
  count: 'invoice/count',
  detail: (id: number | string) => `invoice/${id}`,
  assign: (id: number | string) => `invoice/${id}/assign`,
  assignToMe: (id: number | string) => `invoice/${id}/assign-to-me`,
  setAwait: (id: number | string) => `invoice/${id}/await`,
  toOrder: (id: number | string) => `${config.TICKETS_API_URL}/admin/v1/invoices/${id}/to-order`,
  comments: (id: number | string) => `invoice/${id}/chat`,
  addComment: (id: number | string) => `invoice/${id}/chat`,
  addCommentAttachment: (id: number | string) => `invoice/${id}/comment-attachment`,
  loadCommentAttachment: (id: number | string, attachmentId: number | string) =>
    `invoice/${id}/${attachmentId}/comment-proxy`,
};
