import { message } from 'antd';
import { AxiosError } from 'axios';
import { createEffect } from 'effector';
import { NotificationManager } from 'react-notifications';

export const showedErrorNotification = createEffect((message?: string | AxiosError) => {
  const text = typeof message === 'string' ? message : undefined;
  NotificationManager.error(text, 'Ошибка');
});
export const showedSuccesNotification = createEffect(() => {
  NotificationManager.success('Успешно');
});

export const showedErrorNotificationFx = createEffect((content: string) => {
  const text = typeof content === 'string' ? content : undefined;
  message.error({
    type: 'error',
    content: text || 'Ошибка',
  });
});
export const showedSuccesNotificationFx = createEffect((content?: string) => {
  message.success({
    type: 'success',
    content: content || 'Успешно',
  });
});
