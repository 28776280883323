import { AxiosError } from 'axios';
import { createEffect } from 'effector';
import config from 'config/config';
import api from 'services/api';
import { IcreateDatesFx, ICreateResponse, IDeleteDate, IEditDate } from './types';

const route = `${config.COMMON_API_URL}/admin/v1/events`;

export const createDatesFx = createEffect<IcreateDatesFx, ICreateResponse[], AxiosError>(
  async ({ id, dates }) => {
    const requests = dates.map(async (date) => {
      const response = await api.post(`${route}/${id}/dates`, date);

      return response;
    });

    const response = await Promise.all(requests);
    return response;
  },
);

export const editDateFx = createEffect(async ({ id, dates }: { id: number; dates: IEditDate[] }) => {
  const requests = dates.map(async (date) => {
    const response = await api.patch(`${route}/${id}/dates/${date?.id}`, date);

    return response;
  });

  await Promise.all(requests);
});

export const deleteDateFx = createEffect(async ({ eventID, dateID }: IDeleteDate) => {
  const response = await api.delete(`${route}/${eventID}/dates/${dateID}`);

  return response.data;
});
