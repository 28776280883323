import config from 'config/config';
import COOKIES from '../../constants/COOKIES';
import { getCookie } from '../../lib/cookie';
import api from '../../services/api';

const companyId = getCookie(COOKIES.COMPANY);
const route = `${config.COMMON_API_URL}/admin/v1/${companyId}/landings/persons`;

export default {
  root: `${route}?language_code=ru`,
  detail: (id) => `${route}/${id}`,

  async createTranslation(id, data) {
    const response = await api.post(`${route}/${id}/info`, {
      title: data.title,
      description: data.description,
      language_code: data.language_code,
    });

    return response;
  },

  async updateTranslation(id, lang, data) {
    const response = await api.patch(`${route}/${id}/info/${lang}`, {
      title: data.title,
      description: data.description,
    });

    return response;
  },

  async createQnA(id: number, qnaforUpdate: number[]) {
    const promises = qnaforUpdate.map((qnaforUpdateId) => {
      return api.post(`${config.COMMON_API_URL}/admin/v1/${companyId}/landings/persons/${id}/qna`, {
        qna_id: qnaforUpdateId,
      });
    });

    return Promise.all(promises);
  },

  async deleteQnA(id: number, qnaforDelete: number[]) {
    const promises = qnaforDelete.map((qnaforDeleteId) => {
      return api.delete(
        `${config.COMMON_API_URL}/admin/v1/${companyId}/landings/persons/${id}/qna/${qnaforDeleteId}`,
      );
    });

    return Promise.all(promises);
  },
  async createContentGroups(id: number, contentGroups: number[]) {
    const promises = contentGroups.map((contentGroupId) => {
      return api.post(`${route}/${id}/content_groups`, {
        content_group_id: contentGroupId,
      });
    });

    return Promise.all(promises);
  },

  async deleteContentGroups(id: number, contentGroups: number[]) {
    const promises = contentGroups.map((contentGroupId) => {
      return api.delete(`${route}/${id}/content_groups/${contentGroupId}`);
    });

    return Promise.all(promises);
  },

  async create(personID: number) {
    const promises = api.post(`${route}?language_code=ru`, {
      person_id: personID,
      slug: '',
      company_id: companyId,
    });

    return promises;
  },
};
