import flatten from 'lodash/flatten';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import head from 'lodash/head';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import uniqBy from 'lodash/uniqBy';
import { createSelector } from 'reselect';
import { ANNOUNCEMENTS_TYPES_ENUM } from 'shared/constants/ANNOUNCEMENT_TYPES';
import SEAT_SELECTOR_TYPES from 'shared/constants/SEAT_SELECTOR_TYPES';
import { groupAnnouncementTickets } from 'shared/helpers/announcement';
import { orderTickets } from 'shared/helpers/tickets';

const announcementDataSelector = (state) => state.poolDetail.data;
const announcementIdsSelector = (state) => state.poolDetail.ids;
const announcementDetailDataSelector = (state) => state.userAnnouncementDetail.data;
const selectedAnnouncementsSelector = (state) => state.selectedAnnouncements;

const ticketsCountSelector = createSelector(selectedAnnouncementsSelector, (selectedAnnouncements) =>
  selectedAnnouncements.reduce((acc: number, announcement) => {
    if (announcement.ticketsType === SEAT_SELECTOR_TYPES.PLAIN) {
      return acc + Number(announcement.selectedTickets.length);
    }
    return acc + Number(announcement.selectedTickets);
  }, 0),
);

const selectedTicketsIdsSelector = createSelector(selectedAnnouncementsSelector, (selectedAnnouncements) =>
  flatten(
    selectedAnnouncements.map((announcement) => {
      if (Array.isArray(announcement.selectedTickets)) {
        return announcement.selectedTickets.map((ticket) => ({ id: ticket.id }));
      }

      return { count: announcement.selectedTickets, id: announcement.id };
    }),
  ),
);

const ticketsPriceSelector = createSelector([selectedAnnouncementsSelector], (selectedAnnouncements) =>
  reduce(
    selectedAnnouncements,
    (acc, announcement) => {
      const { total_price: totalPrice } = announcement;

      if (Array.isArray(announcement.selectedTickets)) {
        return acc + announcement.selectedTickets.length * totalPrice;
      }

      return acc + announcement.selectedTickets * totalPrice;
    },
    0,
  ),
);

const announcementsByUserSelector = createSelector([selectedAnnouncementsSelector], (selectedAnnouncements) =>
  groupBy(selectedAnnouncements, 'create_by.company.id'),
);

const sellersSelector = createSelector(selectedAnnouncementsSelector, (announcements) =>
  uniqBy(
    announcements.map((announcement) => announcement.create_by.company),
    'id',
  ),
);

const groupAnnouncementsTickets = (announcements) =>
  announcements.map((announcement) => {
    const tickets = map(orderTickets(announcement.tickets), (ticket) => ({
      ...ticket,
      isPreorderTicket: announcement.announcement_type === ANNOUNCEMENTS_TYPES_ENUM.PREORDER,
    }));
    const groupedTickets = groupAnnouncementTickets(tickets);

    return {
      ...announcement,
      groupedTickets,
    };
  });

const filteredAnnouncementsSelector = createSelector(
  announcementDataSelector,
  announcementIdsSelector,
  (announcements, ids) =>
    reduce(
      ids,
      (announcementAcc, id) => {
        const announcement = announcements[id];
        const tickets = map(orderTickets(announcement.tickets), (ticket) => ({
          ...ticket,
          isPreorderTicket: announcement.announcement_type === ANNOUNCEMENTS_TYPES_ENUM.PREORDER,
        }));
        const groupedTickets = groupAnnouncementTickets(tickets);

        // eslint-disable-next-line no-param-reassign
        announcementAcc[id] = {
          ...announcement,
          groupedTickets,
        };

        return announcementAcc;
      },
      {},
    ),
);

const filteredDetailAnnouncementsSelector = createSelector(announcementDetailDataSelector, (announcements) =>
  groupAnnouncementsTickets(announcements),
);

const selectedAnnouncementsIds = createSelector(selectedAnnouncementsSelector, (selectedAnnouncements) =>
  selectedAnnouncements.reduce((acc, announcement) => {
    acc.add(announcement.id);

    return acc;
  }, new Set()),
);

const selectedAnnouncementCurrency = createSelector(selectedAnnouncementsSelector, (announcements) =>
  get(head(announcements), 'currency'),
);

export {
  ticketsCountSelector,
  ticketsPriceSelector,
  announcementsByUserSelector,
  sellersSelector,
  filteredAnnouncementsSelector,
  selectedAnnouncementsIds,
  filteredDetailAnnouncementsSelector,
  selectedTicketsIdsSelector,
  groupAnnouncementsTickets,
  selectedAnnouncementCurrency,
};
