import isEqual from 'lodash/isEqual';
import { useTranslation } from 'next-i18next';
import React from 'react';
import Button from 'components/Button';
import { AttachmentType } from 'shared/constants/ATTACHMENTS_TYPE';
import { LANGUAGES } from 'shared/constants/LANGUAGES';
import { getAttachments } from 'shared/lib/getAttachments';
import withCheckIsMobile from 'shared/lib/withCheckIsMobile';
import {
  createLandingAttachments,
  deleteLandingAttachments,
  editLandingAttachments,
} from 'shared/sources/attachments';
import landingsPersonalitiesSources from 'shared/sources/landings/landingsPersonalities';
import TrashIcon from '../../../../../static/icons/trash-alt.svg';
import { getDifferenceInContentGroups, getTranslations } from '../../helpers/landingHelpers';
import { IAttachmentTypes, IFormValue, IPersonData } from '../../types/Person';
import PersonsForm from './PersonsForm';

interface PersonsUpdateProps {
  data: IPersonData;
  onSuccess: (data: any) => any;
  onChangeLng: () => any;
  showDeleteModal: () => any;
  isMobile: boolean;
  lng: string;
  closeModal: () => any;
  attachmentTypes: IAttachmentTypes;
}

const PersonsUpdate: React.FC<PersonsUpdateProps> = (props) => {
  const { onSuccess, onChangeLng, showDeleteModal, closeModal, data, lng, isMobile, attachmentTypes } = props;
  const { t } = useTranslation();

  const cover = data.attachments?.find((attachement) => {
    return attachement.attachment_type.code === AttachmentType.Cover;
  });
  const gallery = data.attachments?.find((attachement) => {
    return attachement.attachment_type.code === AttachmentType.Gallery;
  });
  const mainImage = data.attachments?.find((attachement) => {
    return attachement.attachment_type.code === AttachmentType.Main_Image;
  });
  const mainImageInfo = mainImage?.info?.find((i) => i.language_code === lng);
  const coverInfo = cover?.info?.find((i) => i.language_code === lng);

  const info = getTranslations(data.info, lng);

  const initialValues = {
    id: data.id,
    title: info?.title,
    description: info?.description,
    contentGroups:
      (data.content_groups &&
        data.content_groups
          .filter((i) => i && i.id)
          .map((item) => ({
            value: item.id,
            label: item.title,
          }))) ||
      [],
    external: data.person && {
      label: data.person.title,
      value: data.person.id,
    },
    gallery: gallery?.data,
    cover: cover?.data,
    main_image: mainImage?.data,
    ...(coverInfo && { cover_title: coverInfo.title, cover_alt: coverInfo.alt }),
    ...(mainImageInfo && { main_image_title: mainImageInfo.title, main_image_alt: mainImageInfo.alt }),
    qna:
      data?.qna?.map((item) => ({
        value: item.id,
        label: item.title,
      })) || [],
  };

  const handleUpdate = async (submitData: IFormValue) => {
    const formLang = submitData.lng;
    const formData = submitData[lng];

    if (formLang === LANGUAGES.RU && isEqual(formData, initialValues)) {
      return {};
    }

    const translations = getTranslations(data.info, formLang);

    if (translations) {
      await landingsPersonalitiesSources.updateTranslation(data.id, formLang, formData);
    } else {
      await landingsPersonalitiesSources.createTranslation(data.id, { ...formData, language_code: formLang });
    }

    const { contentGroupsforDelete, contentGroupsforUpdate, qnaforUpdate, qnaforDelete } =
      getDifferenceInContentGroups({
        initialData: initialValues,
        formData,
      });

    if (contentGroupsforDelete.length) {
      landingsPersonalitiesSources.deleteContentGroups(data.id, contentGroupsforDelete);
    }

    if (contentGroupsforUpdate.length) {
      const groupsResponse: any = await landingsPersonalitiesSources
        .createContentGroups(data.id, contentGroupsforUpdate)
        .catch((error) => error);

      if (groupsResponse.response?.data?.detail === 'Already exists') {
        return {
          error: {
            response: {
              data: {
                error: ['Данная персона с таким тегом существует'],
              },
            },
          },
        };
      }
    }

    if (qnaforUpdate.length) {
      landingsPersonalitiesSources.createQnA(data.id, qnaforUpdate);
    }

    if (qnaforDelete.length) {
      landingsPersonalitiesSources.deleteQnA(data.id, qnaforDelete);
    }

    const attachments = getAttachments({
      data,
      formValue: submitData,
      attachmentTypes,
    });

    if (attachments?.createAttachment) {
      await createLandingAttachments({ ...attachments?.createAttachment, page: 'persons' });
    }

    if (attachments?.updateAttachment) {
      await editLandingAttachments({ ...attachments?.updateAttachment, page: 'persons' });
    }

    if (attachments?.deleteAttachment) {
      await deleteLandingAttachments({ ...attachments?.deleteAttachment, page: 'persons' });
    }

    return {};
  };

  return (
    <PersonsForm
      key="edit"
      isEdit
      title="landings:personality.edit_title"
      onSubmit={handleUpdate}
      onSuccess={onSuccess}
      onChangeLng={onChangeLng}
      lng={lng}
      initialValues={initialValues}
    >
      <Button dangerTransparent onlyIcon={isMobile} type="button" onClick={showDeleteModal}>
        {isMobile ? <TrashIcon /> : t('forms:delete')}
      </Button>
      <Button type="button" transparent onClick={closeModal}>
        {t('forms:cancel')}
      </Button>
      <Button>{t('forms:save')}</Button>
    </PersonsForm>
  );
};

export default withCheckIsMobile(PersonsUpdate);
