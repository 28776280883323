const ORDER_STATUSES = {
  PROCESSED: 1,
  CANCELED: 2,
  DELIVERY: 3,
  BOOKED: 5,
  BURNT: 6,
  RETURNED: 7,
  FINISHED: 8,
  TIME: 9,
  IN_WORK: 10,
  TICKET_SEARCH: 11,
};

const SALE_STATUSES = {
  1: 'Не в работе',
  2: 'Отменен',
  3: 'Доставка',
  4: 'Оплачен',
  5: 'Бронь',
  6: 'Гарь',
  7: 'Возврат',
  8: 'Завершено',
  9: 'Время',
  10: 'В работе',
  11: 'Поиск билетов',
};

const PURCHASE_STATUSES: { [key: string]: string } = {
  1: 'Не в работе',
  2: 'Отменен',
  3: 'Доставка',
  4: 'Оплачен',
  5: 'Бронь',
  6: 'Гарь',
  7: 'Возврат',
  8: 'Завершено',
  9: 'Время',
  10: 'В работе',
  11: 'Поиск билетов',
};

const BADGE_STATUSES: { [key: string]: string } = {
  1: 'plain',
  2: 'danger',
  3: 'primary',
  4: 'warningToPrimary',
  5: 'warningToDanger',
  6: 'plainToDanger',
  7: 'warningToDanger',
  8: 'success',
  9: 'plain',
  10: 'warning',
  11: 'successToPrimary',
};

const STATUSES_SELECT = [
  {
    label: 'Действующие',
    options: [
      { value: 1, label: 'Не в работе' },
      { value: 3, label: 'Доставка' },
      { value: 5, label: 'Бронь' },
      { value: 9, label: 'Время' },
      { value: 10, label: 'В работе' },
      { value: 11, label: 'Поиск билетов' },
    ],
  },
  {
    label: 'Завершенные',
    options: [
      { value: 8, label: 'Завершено' },
      { value: 7, label: 'Возврат' },
      { value: 2, label: 'Отмена' },
      { value: 6, label: 'Гарь' },
    ],
  },
];

const PRICE_CHANGE_TYPES = {
  NUMBER: 10,
  PERCENTAGE: 70,
  FIX: 80,
};

const DESTINATION_TYPES = {
  MARKUP: 10,
  DISCOUNT: 20,
};

const ORDER_ACTIONS = {
  ADD_TICKETS_FROM_POOL: 'ADD_TICKETS_FROM_POOL',
  ADD_TICKETS: 'ADD_TICKETS',
  REMOVE_TICKETS: 'REMOVE_TICKETS',
  REMOVE_TICKETS_FROM_POOL: 'REMOVE_TICKETS_FROM_POOL',
  CANCEL_ORDER: 'CANCEL_ORDER',
  REFUND: 'REFUND',
  SET_EXTERNAL_ID: 'SET_EXTERNAL_ID',
  DELETE_EXTERNAL_ID: 'DELETE_EXTERNAL_ID',
  ASSIGN_ORDER: 'ASSIGN_ORDER',
  EDIT_USER_CUSTOMER: 'EDIT_USER_CUSTOMER',
  RETURN_ORDER_ON_SALE: 'RETURN_ORDER_ON_SALE',
  OPEN_CHAT: 'OPEN_CHAT',
  SET_TICKET_SEARCH_STATUS: 'SET_TICKET_SEARCH_STATUS',
  TRANSFER_PREORDER_TICKETS: 'TRANSFER_PREORDER_TICKETS',
  DOWNLOAD_REPORT: 'DOWNLOAD_REPORT',
};

const STATUS_IN_QUERY = {
  [ORDER_STATUSES.FINISHED]: ORDER_STATUSES.FINISHED,
  [ORDER_STATUSES.RETURNED]: ORDER_STATUSES.RETURNED,
  [ORDER_STATUSES.CANCELED]: ORDER_STATUSES.CANCELED,
  [ORDER_STATUSES.BURNT]: ORDER_STATUSES.BURNT,
};

const REJECT_REASONS = {
  OTHER: 0,
};

const SOURCE_TYPES = {
  NONE: 0,
  ADV: 1,
  SEO: 2,
};

export {
  SALE_STATUSES,
  PURCHASE_STATUSES,
  BADGE_STATUSES,
  STATUSES_SELECT,
  ORDER_STATUSES,
  PRICE_CHANGE_TYPES,
  ORDER_ACTIONS,
  DESTINATION_TYPES,
  STATUS_IN_QUERY,
  REJECT_REASONS,
  SOURCE_TYPES,
};
