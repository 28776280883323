import Modal from 'components/Modal';
import ClientsMergeModal from 'components/Modals/ClientsMergeModal';
import ClientsModal from 'components/Modals/ClientsModal';
import CompanyMemberPermissions from 'components/Modals/CompanyMemberPermissions';
import ConfirmModal from 'components/Modals/ConfirmModal';
import DrawerModal from 'components/Modals/DrawerModal';
import LandingsErrorsModal from 'components/Modals/LandingsErrorsModal';
import NewAnnouncementModal from 'components/Modals/NewAnnouncementModal';
import OrderPaymentTimeModal from 'components/Modals/OrderPaymentTimeModal';
import OrderUpdatesModal from 'components/Modals/OrderUpdatesModal';
import PartnerTicketsModal from 'components/Modals/PartnerTicketsModal';
import ReferenceErrorsModal from 'components/Modals/ReferenceErrorsModal';
import RegistrationNoticeModal from 'components/Modals/RegistrationNoticeModal';
import ReportsDescriptionModal from 'components/Modals/ReportsDescriptionModal';
import ReportsEventsFilterModal from 'components/Modals/ReportsEventsFilterModal';
import ConstructorModal from 'components/Pages/Landings/ConstructorModal';
import PageConstructorModal from 'components/Pages/Landings/PageConstructorModal';
import WidgetModal from 'components/Pages/Widget/WidgetModal';
import WidgetModalV2 from 'components/Pages/Widget/WidgetModalV2';
import modalsConfig from 'config/modals';
import omit from 'lodash/omit';
import { withRouter } from 'next/router';
import queryString from 'query-string';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CompanyAtolConfigModal } from 'pages/company/ui/atol-config/ui/modal';
import { CompanyEmailConfigModal } from 'pages/company/ui/email-config/ui/modal';
import { CompanyLegalInfoModal } from 'pages/company/ui/legal-info/ui/modal';
import { CompanyMemberModal } from 'pages/company/ui/members/ui/modal';
import { CompanyAddPaymentMethodModal } from 'pages/company/ui/payment-methods/ui/modal';
import { CompanyPhoneModal } from 'pages/company/ui/phones/ui/modal';
import { CompanyAddProviderModal } from 'pages/company/ui/providers/ui/providers-modal';
import { MarkupDeleteModal } from 'pages/markups/ui/delete-modal';
import { default as ActualModal } from 'pages/reference/actual/ui/modal';
import CurrencyHistoryModal from 'pages/reference/currency/ui/history-modal';
import CurrencyModal from 'pages/reference/currency/ui/modal';
import { ExpansionModal } from 'pages/reference/expansions';
import { RoleModal } from 'widgets/modals/role';
import {
  closeModal,
  openModal,
  openRouterModal,
  closeRouterModal,
  removePreviousModal,
  changeModalState,
} from 'entities/modal';
import { EVENTS } from 'shared/constants/EVENTS';
import MODAL from 'shared/constants/MODALS';
import { updateQuery, replaceQuery, Query } from 'shared/helpers/routes';
import Emmiter from 'shared/services/Emmiter';
import { ModalFunctions } from '../../../interfaces/modal';
import LandingEventsModal from '../../pages/landings/events/EventsModal';
import LandingPersonsModal from '../../pages/landings/persons/PersonsModal';
import LandingPlacesModal from '../../pages/landings/places/PlacesModal';
import GlassTicketsModalContainer from '../GlassTicketsModalContainer';
import MergeEventsModalContainer from '../MergeEventsModalContainer';
import AnnouncementCommentModalContainer from '../Modals/AnnouncementCommentModalContainer';
import AnnouncementHistoryModalContainer from '../Modals/AnnouncementHistoryModalContainer';
import AnnouncementPlanningPriceModalContainer from '../Modals/AnnouncementPlanningPriceModalContainer';
import AnnouncementPriceEditModalContainer from '../Modals/AnnouncementPriceEditModalContainer';
import AnnouncementStatusEditModalContainer from '../Modals/AnnouncementStatusEditModalContainer';
import CompanyInfoModalContainer from '../Modals/CompanyInfoModalContainer';
import EditMemberModalContainer from '../Modals/EditMemberModalContainer';
import InvoicesAssignManagerModalContainer from '../Modals/InvoicesAssignManagerModalContainer';
import InvoicesEditEventModalContainer from '../Modals/InvoicesEditEventModalContainer';
import InvoicesEditUserCustomerModalContainer from '../Modals/InvoicesEditUserCustomerModalContainer';
import InvoicesRejectModalContainer from '../Modals/InvoicesRejectModalContainer';
import LandingsSitesEditInfoModalContainer from '../Modals/LandingsSitesEditInfoModalContainer';
import OrderAddNotificationModalContainer from '../Modals/OrderAddNotificationModalContainer';
import OrderAssignManagerModalContainer from '../Modals/OrderAssignManagerModalContainer';
import OrderCancelModalContainer from '../Modals/OrderCancelModalContainer';
import OrderDiscountModalContainer from '../Modals/OrderDiscountModalContainer';
import OrderEditDeliveryModalContainer from '../Modals/OrderEditDeliveryModalContainer';
import OrderEditUserCustomerModalContainer from '../Modals/OrderEditUserCustomerModalContainer';
import OrderExternalIdModalContainer from '../Modals/OrderExternalIdModalContainer';
import OrderNewTicketsModalContainer from '../Modals/OrderNewTicketsModalContainer';
import OrderRefundModalContainer from '../Modals/OrderRefundModalContainer';
import OrdersAssignMemberModalContainer from '../Modals/OrdersAssignMemberModalContainer';
import OrdersCancelModalContainer from '../Modals/OrdersCancelModalContainer';
import ParsersAddEventModalContainer from '../Modals/ParsersAddEventModalContainer';
import ParsersExternalIdModalContainer from '../Modals/ParsersExternalIdModalContainer';
import PreorderModalContainer from '../Modals/PreorderModalContainer';
import ReportsConfigModalContainer from '../Modals/ReportsConfigModalContainer';
import BookingUpdatesModal from '../Pages/Announcements/BookingUpdatesModal';
import BookTicketsModal from '../Pages/Announcements/BookTicketsModal';
import ArticleModal from '../Pages/Landings/ArticleModal';
import LandingsCustomPageModal from '../Pages/Landings/LandingsCustomPageModal';
import SiteModal from '../Pages/Landings/SiteModal';
import LinkEventModal from '../Pages/Parsers/LinkEventModal';
import SchemePoolModalContainer from '../Pages/Pool/SchemePoolModalContainer';
import WidgetPatternsModalContainer from '../Pages/Widgets/WidgetPatternsModalContainer';

const MODALS = {
  [MODAL.REFERENCE_CURRENCY]: CurrencyModal,
  [MODAL.REFERENCE_CURRENCY_HISTORY]: CurrencyHistoryModal,

  [MODAL.REFERENCE_EXPANSION]: ExpansionModal,
  [MODAL.REFERENCE_ERRORS_MODAL]: ReferenceErrorsModal,
  [MODAL.REFERENCE_ACTUAL]: ActualModal,

  [MODAL.COMPANY_MEMBER]: CompanyMemberModal,
  [MODAL.COMPANY_MEMBER_PERMISSIONS]: CompanyMemberPermissions,
  [MODAL.COMPANY_ADD_PROVIDER]: CompanyAddProviderModal,
  [MODAL.COMPANY_ADD_PAYMENT_METHOD]: CompanyAddPaymentMethodModal,
  [MODAL.COMPANY_EMAIL_CONFIG_MODAL]: CompanyEmailConfigModal,
  [MODAL.COMPANY_LEGAL_INFO_MODAL]: CompanyLegalInfoModal,
  [MODAL.COMPANY_ATOL_CONFIG_MODAL]: CompanyAtolConfigModal,

  [MODAL.CLIENTS_MODAL]: ClientsModal,
  [MODAL.CLIENTS_MERGE_MODAL]: ClientsMergeModal,

  [MODAL.BOOK_TICKETS_MODAL]: BookTicketsModal,
  [MODAL.BOOKING_UPDATES_MODAL]: BookingUpdatesModal,

  [MODAL.ORDER_UPDATES_MODAL]: OrderUpdatesModal,
  [MODAL.PARTNER_TICKETS_MODAL]: PartnerTicketsModal,
  [MODAL.ORDER_CANCEL_MODAL]: OrderCancelModalContainer,
  [MODAL.ORDERS_CANCEL_MODAL]: OrdersCancelModalContainer,
  [MODAL.ORDERS_ASSIGN_MEMBER]: OrdersAssignMemberModalContainer,
  [MODAL.ORDER_REFUND_MODAL]: OrderRefundModalContainer,
  [MODAL.ORDER_EDIT_USER_CUSTOMER_MODAL]: OrderEditUserCustomerModalContainer,
  [MODAL.ORDER_ASSIGN_MANAGER_MODAL]: OrderAssignManagerModalContainer,
  [MODAL.ORDER_PAYMENT_TIME_MODAL]: OrderPaymentTimeModal,
  [MODAL.ORDER_EXTERNAL_ID_MODAL]: OrderExternalIdModalContainer,
  [MODAL.ORDER_NEW_TICKETS_MODAL]: OrderNewTicketsModalContainer,
  [MODAL.ORDER_DISCOUNT_MODAL]: OrderDiscountModalContainer,
  [MODAL.ORDER_EDIT_DELIVERY_MODAL]: OrderEditDeliveryModalContainer,
  [MODAL.ORDER_ADD_NOTIFICATION_MODAL]: OrderAddNotificationModalContainer,

  [MODAL.INVOICES_ASSIGN_MANAGER_MODAL]: InvoicesAssignManagerModalContainer,
  [MODAL.INVOICES_EDIT_USER_CUSTOMER_MODAL]: InvoicesEditUserCustomerModalContainer,
  [MODAL.INVOICES_REJECT_MODAL]: InvoicesRejectModalContainer,
  [MODAL.INVOICES_EDIT_EVENT_MODAL]: InvoicesEditEventModalContainer,

  [MODAL.LINK_EVENT_MODAL]: LinkEventModal,

  // NEW LANDINGS
  [MODAL.SITE_MODAL]: SiteModal,
  [MODAL.LANDINGS_CUSTOM_PAGE_MODAL]: LandingsCustomPageModal,
  [MODAL.PERSONALITY_MODAL]: LandingPersonsModal,
  [MODAL.LANDINGS_EVENT_MODAL]: LandingEventsModal,
  [MODAL.PLAYGROUND_MODAL]: LandingPlacesModal,
  [MODAL.CONSTRUCTOR_MODAL]: ConstructorModal,
  [MODAL.PAGE_CONSTRUCTOR_MODAL]: PageConstructorModal,
  [MODAL.LANDINGS_SITES_EDIT_INFO_MODAL]: LandingsSitesEditInfoModalContainer,
  [MODAL.LANDINGS_ERRORS_MODAL]: LandingsErrorsModal,

  [MODAL.CONFIRM_MODAL]: ConfirmModal,
  [MODAL.DELETE_MARKUP]: MarkupDeleteModal,

  [MODAL.NEW_ANNOUNCEMENT_MODAL]: NewAnnouncementModal,
  [MODAL.ANNOUNCEMENT_HISTORY_MODAL]: AnnouncementHistoryModalContainer,
  [MODAL.ANNOUNCEMENT_PLANNING_PRICE_MODAL]: AnnouncementPlanningPriceModalContainer,
  [MODAL.ANNOUNCEMENT_COMMENT_MODAL]: AnnouncementCommentModalContainer,
  [MODAL.ANNOUNCEMENT_PRICE_EDIT_MODAL]: AnnouncementPriceEditModalContainer,
  [MODAL.ANNOUNCEMENT_STATUS_EDIT_MODAL]: AnnouncementStatusEditModalContainer,

  [MODAL.ROLE_MODAL]: RoleModal,

  [MODAL.WIDGET_MODAL]: WidgetModal,
  [MODAL.WIDGET_MODAL_V2]: WidgetModalV2,
  [MODAL.WIDGET_PATTERN_MODAL]: WidgetPatternsModalContainer,

  [MODAL.MERGE_EVENTS_MODAL]: MergeEventsModalContainer,

  [MODAL.POOL_SCHEME]: SchemePoolModalContainer,

  [MODAL.GLASS_TICKETS_MODAL]: GlassTicketsModalContainer,

  [MODAL.PREORDER_MODAL]: PreorderModalContainer,

  [MODAL.DRAWER_MODAL]: DrawerModal,

  [MODAL.PARSERS_ADD_EVENT_MODAL]: ParsersAddEventModalContainer,
  [MODAL.PARSERS_EXTERNAL_ID_MODAL]: ParsersExternalIdModalContainer,

  [MODAL.REPORTS_DESCRIPTION_MODAL]: ReportsDescriptionModal,
  [MODAL.REPORTS_EVENTS_FILTER_MODAL]: ReportsEventsFilterModal,

  [MODAL.EDIT_MEMBER_MODAL]: EditMemberModalContainer,
  [MODAL.COMPANY_INFO_MODAL]: CompanyInfoModalContainer,
  [MODAL.COMPANY_PHONE_MODAL]: CompanyPhoneModal,

  [MODAL.REPORTS_CONFIG_MODAL]: ReportsConfigModalContainer,

  [MODAL.REGISTRATION_NOTICE_MODAL]: RegistrationNoticeModal,
};

interface ModalProps {
  data?: any;
  state?: any;
  router: {
    query: any;
    pathname: string;
  };
  isOpen: boolean;
  closeModal: ModalFunctions['closeModal'];
  removePreviousModal: ModalFunctions['removePreviousModal'];
  closeRouterModal: ModalFunctions['closeRouterModal'];
  openRouterModal: ModalFunctions['openRouterModal'];
  changeModalState: ModalFunctions['changeModalState'];
  openModal: ModalFunctions['openModal'];
  modalType?: string;
  modalState?: string;
  routerModalType?: string;
  prevModal?: any;
}

interface ModalState {
  popoverIsOpen: boolean;
}

class ModalContainer extends Component<ModalProps, ModalState> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    data: {},
  };

  popoverIsOpen = false;

  componentDidMount() {
    const {
      isOpen,
      router: {
        query: { modalType, modalId, modalState },
      },
    } = this.props;

    if (modalType && !isOpen) {
      this.props.openRouterModal({ modalType, modalState, data: { id: modalId } });
    }

    window.addEventListener('popstate', this.checkModal);
  }

  componentWillUnmount() {
    this.props.closeModal();
    document.removeEventListener('keyup', this.handleKeyUp);
    window.removeEventListener('popstate', this.checkModal);
  }

  handleKeyUp = ({ keyCode }) => {
    if (keyCode === 27) {
      if (this.modalData && this.modalData.confirmClose && this.popoverIsOpen) {
        this.closePopover();
        return;
      }

      this.close();
    }
  };

  get modalData() {
    const { modalType, routerModalType, data } = this.props;
    const modalConfig = modalsConfig[routerModalType] || modalsConfig[modalType] || { modalData: {} };

    return {
      ...modalConfig.modalData,
      ...(data.modalData || {}),
    };
  }

  checkModal = () => {
    const {
      isOpen,
      router: {
        query: { modalType, modalId },
      },
    } = this.props;

    if (!modalType && isOpen) {
      this.props.closeRouterModal();
    }

    if (modalType && !isOpen) {
      this.props.openRouterModal({ modalType, data: { id: modalId } });
    }
  };

  closePopover = () => {
    Emmiter.emit(EVENTS.MODAL_TOGGLE_POPOVER, false);
    this.popoverIsOpen = false;
  };

  close = () => {
    const { routerModalType, prevModal, router } = this.props;

    if (this.modalData && this.modalData.confirmClose && !this.popoverIsOpen) {
      Emmiter.emit(EVENTS.MODAL_TOGGLE_POPOVER, true);
      this.popoverIsOpen = true;
      return;
    }

    if (prevModal) {
      updateQuery({
        modalType: prevModal.modalType,
        modalId: prevModal.data.id,
      });
      this.props.removePreviousModal();
      return;
    }

    if (this.popoverIsOpen) {
      this.closePopover();
    }

    if (routerModalType) {
      if (router?.pathname !== '/events') {
        const newQuery: Query = omit(queryString.parse(window.location.search), [
          'modalType',
          'modalId',
          'event',
        ]);

        replaceQuery(newQuery);
      }

      document.removeEventListener('keyup', this.handleKeyUp);
      this.props.closeRouterModal();
      return;
    }

    if (this.modalData.onModalClose) {
      this.modalData.onModalClose();
    }

    this.props.closeModal();
    document.removeEventListener('keyup', this.handleKeyUp);
  };

  forceClose = () => {
    const { routerModalType } = this.props;

    if (this.popoverIsOpen) {
      this.closePopover();
    }

    if (routerModalType) {
      const newQuery: any = omit(queryString.parse(window.location.search), [
        'modalType',
        'modalId',
        'isPreview',
      ]);

      replaceQuery(newQuery);
      this.props.closeRouterModal();
      return;
    }

    this.props.closeModal();
    document.removeEventListener('keyup', this.handleKeyUp);
  };

  render() {
    const { modalType, routerModalType, data, state } = this.props;

    const CurrentModal = MODALS[routerModalType] || MODALS[modalType];

    const { CloseButton } = data;

    const modalData = omit(this.modalData, ['onModalClose']);

    const { withoutCloseButton } = modalData;

    const omittedModalData = omit(modalData, 'onModalSubmit');

    if (this.props.isOpen && CurrentModal) {
      return (
        <Modal
          close={this.close}
          openModal={this.props.openModal}
          closeModal={this.props.closeModal}
          popoverIsOpen={this.popoverIsOpen}
          closePopover={this.closePopover}
          {...omittedModalData}
        >
          {document.addEventListener('keyup', this.handleKeyUp)}
          <Modal.Body className="modal__body" {...omittedModalData}>
            {CloseButton
              ? React.cloneElement(CloseButton, { onClick: this.close })
              : !withoutCloseButton && (
                  <Modal.Close className="modal__close" onClick={this.close} {...omittedModalData} />
                )}

            <CurrentModal
              data={data}
              router={this.props.router}
              closeModal={this.close}
              forceCloseModal={this.forceClose}
              changeState={this.props.changeModalState}
              modalState={state}
            />
          </Modal.Body>
        </Modal>
      );
    }
    return null;
  }
}

const mapStateToProps = (state) => ({
  isOpen: state.modal.isOpen,
  modalType: state.modal.modalType,
  modalState: state.modal.modalState,
  prevModal: state.modal.prevModal,
  routerModalType: state.modal.routerModalType,
  data: state.modal.data,
  state: state.modal.state,
});

const mapDispatchToProps = {
  closeModal,
  openModal,
  openRouterModal,
  closeRouterModal,
  removePreviousModal,
  changeModalState,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModalContainer));
