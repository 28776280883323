import { find, head, isArray, isEqual } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import React, { useState, useEffect } from 'react';
import { NotificationManager } from 'react-notifications';
import { currencySources } from 'entities/currency';
import {
  ILandingBackgroundPatternAttachment,
  ILandingBannerAttachment,
  ILandingGalleryAttachment,
} from 'shared/api/landings/constructor';
import { ILandingData, ILandingTranslation } from 'shared/api/landings/sites/types';
import { AttachmentTypes } from 'shared/constants/ATTACHMENTS_TYPE';
import CONSTRUCTOR_FIELDS from 'shared/constants/CONSTRUCTOR_FIELDS';
import CONSTRUCTOR_FILTERS from 'shared/constants/CONSTRUCTOR_FILTERS';
import CONSTRUCTOR_MODULES from 'shared/constants/CONSTRUCTOR_MODULES';
import { LANGUAGES, LANGUAGES_NAMES } from 'shared/constants/LANGUAGES';
import SUBMIT_ACTIONS from 'shared/constants/SUBMIT_ACTIONS';
import { getLanguageCurrency } from 'shared/helpers/currency';
import { checkForInitialValues } from 'shared/helpers/form';
import { findTranslation } from 'shared/helpers/translations';
import useListItem from 'shared/lib/useListItem';
import api from 'shared/services/api';
import landingsSitesSources from 'shared/sources/landings/landingsSites';
import localesSource from 'shared/sources/locales';
import { Select } from '../../../FormControls';
import StepModal from '../../../Modals/StepModal';
import ProgressBar from '../../../ProgressBar';
import Spinner from '../../../Spinner';
import {
  createConstuctorAttachments,
  createGalleryAttachments,
  deleteConstuctorAttachments,
  deleteGalleryAttachments,
  updateConstuctorAttachments,
  updateGalleryAttachments,
} from './lib/attachments';
import Styled from './styles';

interface ICounstructorModalState {
  data: ILandingData | null;
  step: number;
  lng: string;
  initialValues: { [key: string]: any } | null;
  availableCurrencies: any[];
  locales: any[];
  isLoading: boolean;
}

const getDiff = (initial: any, current: any) => {
  if (!initial || !current) return false;
  if (isArray(current)) {
    return !isEqual(initial, current);
  }

  const diff = Object.entries(current)
    .filter(([key, value]) => {
      if (['string', 'number', 'boolean'].includes(typeof value)) return initial[key] !== value;
      if (isArray(value)) return !isEqual(initial[key], value);

      return !isEqual(initial[key], value);
    })
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

  return diff;
};

const ConstructorModal = (props: any) => {
  const modalId = props.data.tech_name || props.data.id;
  const [state, setState] = useState<ICounstructorModalState>({
    step: 0,
    lng: LANGUAGES.RU,
    data: null,
    initialValues: null,
    availableCurrencies: [],
    locales: [],
    isLoading: true,
  });
  const StepComponent: any =
    CONSTRUCTOR_MODULES[state.lng === LANGUAGES.RU ? LANGUAGES.RU : 'translations'].STEP_COMPONENT[
      state.step
    ];
  const { get } = useListItem({ source: landingsSitesSources, newDetailApi: true });
  let updatedTempData = null;

  const loadData = async ({ lang, currencies }) => {
    try {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));

      const responseData = await get(modalId, lang);
      const bgPatternAttachment = responseData?.attachments?.find(
        (attachment: ILandingBackgroundPatternAttachment) =>
          attachment?.attachment_type?.id === AttachmentTypes.background_pattern.id,
      );
      const currentData = {
        ...responseData,
        ...findTranslation(responseData.info, state.lng),
        ...(responseData.acquiring_list && {
          site_acquiring_config: responseData.acquiring_list[0],
          site_acquiring_second_config: responseData.acquiring_list[1],
        }),
        main_page_banner_image: responseData?.attachments?.find(
          (attachment: ILandingBannerAttachment) =>
            attachment?.attachment_type?.id === AttachmentTypes.banner.id,
        )?.data,
        main_page_gallery: responseData?.attachments?.find(
          (attachment: ILandingGalleryAttachment) =>
            attachment?.attachment_type?.id === AttachmentTypes.gallery.id,
        )?.data?.origin,
        ui_config_bg_pattern: isArray(bgPatternAttachment?.data)
          ? head(bgPatternAttachment?.data)
          : bgPatternAttachment?.data?.img,
        ...(responseData.qna && {
          qna:
            responseData.qna?.map((item) => ({
              value: item.id,
              label: item.title,
            })) || [],
        }),
        ...(!isEmpty(responseData.site_atol_config) && {
          site_atol_config: {
            value: responseData.site_atol_config.id,
            label: responseData.site_atol_config.company_name,
          },
        }),
        ...(!isEmpty(responseData.email_config) && {
          email_config: {
            value: responseData.email_config.id,
            label: responseData.email_config.login,
          },
        }),
        ...(!isEmpty(responseData.contact_info_address) && {
          contact_info_address: responseData.contact_info_address,
        }),
        ui_config_qna_name:
          findTranslation(responseData.info, state.lng)?.ui_config_qna_name || 'Вопросы и ответы',
        favicon_type: responseData.ui_config_favicon && responseData.ui_config_favicon[0],
        favicon_color: responseData.ui_config_favicon && responseData.ui_config_favicon[1],
        ui_config_custom_pages_group_name:
          findTranslation(responseData.info, state.lng)?.ui_config_custom_pages_group_name || 'Еще',
        ui_config_tabs: responseData.ui_config_tabs || 1,
        ui_config_container_color: responseData.ui_config_container_color || '#FFFFFF',
        ui_config_container_text_color: responseData.ui_config_container_text_color || '#000000',
        ui_config_index_ordering:
          responseData.ui_config_index_ordering &&
          responseData.ui_config_index_ordering.filter((i) => i !== 'archive'),
        ...(!isEmpty(responseData.ui_config_filters_list) && {
          ui_config_filters_list: responseData.ui_config_filters_list.map(
            (filter) => CONSTRUCTOR_FILTERS[filter],
          ),
        }),
        ui_config_titles:
          CONSTRUCTOR_FIELDS.titles[responseData.ui_config_titles] || CONSTRUCTOR_FIELDS.titles[1],
        ui_config_layout:
          CONSTRUCTOR_FIELDS.layout[responseData.ui_config_layout] || CONSTRUCTOR_FIELDS.layout[1],
        ...(responseData.contact_info_phone && {
          contact_info_phone: {
            label: responseData.contact_info_phone,
            value: responseData.contact_info_phone,
          },
        }),
        ...(responseData.contact_info_second_phone && {
          contact_info_second_phone: {
            label: responseData.contact_info_second_phone,
            value: responseData.contact_info_second_phone,
          },
        }),
        ...(!isEmpty(responseData.languages) && {
          languages: responseData.languages.map((language) => ({
            label: LANGUAGES_NAMES[language],
            value: language,
          })),
        }),
        ...(!isEmpty(responseData.main_lang) && {
          main_lang: {
            label: LANGUAGES_NAMES[responseData.main_lang],
            value: responseData.main_lang,
          },
        }),
        ...(!isEmpty(responseData.currencies) && {
          currencies: responseData.currencies.map((currency) =>
            find(currencies, (o) => o.value === currency.code),
          ),
        }),
      };

      const data = {
        data: currentData,
        initialValues: currentData,
        isLoading: false,
      };

      setState((prevState) => ({
        ...prevState,
        ...data,
      }));

      updatedTempData = data;

      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));

      return data;
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));

      return NotificationManager.error('Ошибка');
    }
  };

  useEffect(() => {
    api
      .get(currencySources.root)
      .then((response) => {
        const currencies = response.data.data.results.map((item) => ({
          value: item.char_code,
          label: item.name,
        }));
        loadData({
          lang: LANGUAGES.RU,
          currencies,
        }).catch((err) => new Error(err));
        setState((prevState) => ({
          ...prevState,
          availableCurrencies: currencies,
        }));
      })
      .catch((err) => new Error(err));
  }, [state.lng]);

  useEffect(() => {
    api
      .get(localesSource.available)
      .then((response) => {
        const supportedLocales = response.data.map((item) => ({
          value: item.code,
          name: item.label,
        }));
        setState((prevState) => ({
          ...prevState,
          locales: [...prevState.locales, ...supportedLocales],
        }));
      })
      .catch((err) => new Error(err));
  }, []);

  const onChangeLng = ({ target: { value: newLng } }) => {
    setState((prevState) => ({
      ...prevState,
      lng: newLng,
      step: 0,
      isLoading: true,
    }));
  };

  const goBack = () => {
    setState((prevState) => ({
      ...prevState,
      step: prevState.step - 1,
    }));
  };

  const updateTempData = (newData) => {
    updatedTempData = newData;
  };

  const langTranslation = findTranslation(state.data?.info as ILandingTranslation[], state.lng);
  const siteName = langTranslation
    ? langTranslation.site_name
    : findTranslation(state.data?.info as ILandingTranslation[], LANGUAGES.RU)?.site_name || '';

  const addData = async (newData) => {
    const currentData = { ...state.data, ...checkForInitialValues(state.initialValues, newData) };

    const siteAcquiringSecondConfig = currentData.site_acquiring_second_config
      ? currentData.site_acquiring_second_config.value || currentData.site_acquiring_second_config
      : currentData.site_acquiring_second_config;
    const siteAcquiringConfig = currentData.site_acquiring_config
      ? currentData.site_acquiring_config.value || currentData.site_acquiring_config
      : null;

    if (
      state.step ===
        CONSTRUCTOR_MODULES[state.lng === LANGUAGES.RU ? LANGUAGES.RU : 'translations'].STEPS_WORDS.length -
          1 ||
      newData.submit_action === SUBMIT_ACTIONS.CREATE
    ) {
      const valuesToUpdate: any = getDiff(state.initialValues, currentData);

      const submitData =
        state.lng !== LANGUAGES.RU
          ? {
              ...valuesToUpdate,
              ...(valuesToUpdate.contact_info_address && {
                contact_info_address: valuesToUpdate.contact_info_address,
              }),
              id: modalId,
            }
          : omit(
              {
                ...valuesToUpdate,
                ...(valuesToUpdate.content_groups && {
                  content_groups: valuesToUpdate.content_groups.map((item) => item.id || item),
                }),
                legal_information: valuesToUpdate.legal_information?.id,
                template_set: valuesToUpdate.template_set?.id,
                acquiring_list: [siteAcquiringConfig, siteAcquiringSecondConfig].filter(Boolean),
                ...(Object.prototype.hasOwnProperty.call(valuesToUpdate, 'site_atol_config') && {
                  site_atol_config_id: valuesToUpdate.site_atol_config
                    ? valuesToUpdate.site_atol_config.value || valuesToUpdate.site_atol_config
                    : null,
                }),
                ...(Object.prototype.hasOwnProperty.call(valuesToUpdate, 'email_config') && {
                  email_config_id: valuesToUpdate.email_config
                    ? valuesToUpdate.email_config.value || valuesToUpdate.email_config
                    : null,
                }),
                ...(Object.prototype.hasOwnProperty.call(valuesToUpdate, 'ui_config_filters_list') && {
                  ui_config_filters_list: valuesToUpdate.ui_config_filters_list
                    ? valuesToUpdate.ui_config_filters_list.map((filter) => filter.value || filter)
                    : [],
                }),
                ...(Object.prototype.hasOwnProperty.call(valuesToUpdate, 'languages') && {
                  languages:
                    valuesToUpdate.languages && currentData.ui_config_show_translation
                      ? valuesToUpdate.languages.map((language) => language.value || language)
                      : [],
                }),
                ...(valuesToUpdate.contact_info_address && {
                  contact_info_address: valuesToUpdate.contact_info_address,
                }),
                ...(Object.prototype.hasOwnProperty.call(valuesToUpdate, 'main_lang') && {
                  main_lang:
                    valuesToUpdate.main_lang && currentData.ui_config_show_translation
                      ? valuesToUpdate.main_lang.value || valuesToUpdate.main_lang
                      : LANGUAGES.RU,
                }),
                ...(Object.prototype.hasOwnProperty.call(valuesToUpdate, 'currencies') && {
                  currencies: valuesToUpdate.currencies
                    ? valuesToUpdate.currencies.map((currency) => currency.value || currency)
                    : [],
                }),
                ...(Object.prototype.hasOwnProperty.call(valuesToUpdate, 'main_page_gallery') && {
                  main_page_gallery:
                    valuesToUpdate.main_page_gallery &&
                    valuesToUpdate.main_page_gallery.map((item) => item.img || item),
                  main_page_gallery_webp:
                    valuesToUpdate.main_page_gallery &&
                    valuesToUpdate.main_page_gallery.map((item) => item.img_webp || item),
                }),
                ...(Object.prototype.hasOwnProperty.call(valuesToUpdate, 'contact_info_phone') && {
                  contact_info_phone: valuesToUpdate.contact_info_phone
                    ? valuesToUpdate.contact_info_phone.label || valuesToUpdate.contact_info_phone
                    : null,
                }),
                ...(Object.prototype.hasOwnProperty.call(valuesToUpdate, 'contact_info_second_phone') && {
                  contact_info_second_phone: valuesToUpdate.contact_info_second_phone
                    ? valuesToUpdate.contact_info_second_phone.label ||
                      valuesToUpdate.contact_info_second_phone
                    : null,
                }),
                ...(Object.prototype.hasOwnProperty.call(valuesToUpdate, 'ui_config_layout') && {
                  ui_config_layout: valuesToUpdate.ui_config_layout
                    ? valuesToUpdate.ui_config_layout.value || valuesToUpdate.ui_config_layout
                    : null,
                }),
                ...(Object.prototype.hasOwnProperty.call(valuesToUpdate, 'ui_config_titles') && {
                  ui_config_titles: valuesToUpdate.ui_config_titles
                    ? valuesToUpdate.ui_config_titles.value || valuesToUpdate.ui_config_titles
                    : null,
                }),
                ...(Object.prototype.hasOwnProperty.call(valuesToUpdate, 'language_currency') &&
                  getLanguageCurrency(valuesToUpdate)),
              },
              ['favicon_type', 'favicon_color', 'triggers', 'color', 'rounding_corners', 'submit_action'],
            );

      let update = null;

      const galleryAttachment = state.initialValues?.attachments.find(
        (attachment: ILandingGalleryAttachment) =>
          attachment?.attachment_type?.id === AttachmentTypes.gallery.id,
      );

      if (valuesToUpdate?.main_page_gallery && isEmpty(galleryAttachment)) {
        createGalleryAttachments({
          valuesToUpdate,
          modalId,
        });
      }

      if (valuesToUpdate?.main_page_gallery && !isEmpty(galleryAttachment)) {
        updateGalleryAttachments({
          valuesToUpdate,
          modalId,
          galleryAttachment,
        });
      }

      if (!isEmpty(galleryAttachment?.data?.origin) && isEmpty(newData?.main_page_gallery)) {
        deleteGalleryAttachments({
          modalId,
        });
      }

      const bannerAttachment = state.initialValues?.attachments.find(
        (attachment: ILandingBannerAttachment) =>
          attachment?.attachment_type?.id === AttachmentTypes.banner.id,
      );
      const backgroundPatternAttachment = state.initialValues?.attachments.find(
        (attachment: ILandingBackgroundPatternAttachment) =>
          attachment?.attachment_type?.id === AttachmentTypes.background_pattern.id,
      );

      if (
        (valuesToUpdate?.main_page_banner_image && isEmpty(bannerAttachment)) ||
        (valuesToUpdate?.ui_config_bg_pattern && isEmpty(backgroundPatternAttachment))
      ) {
        createConstuctorAttachments({ valuesToUpdate, modalId });
      }

      if (
        (valuesToUpdate?.main_page_banner_image && !isEmpty(bannerAttachment)) ||
        (valuesToUpdate?.ui_config_bg_pattern && !isEmpty(backgroundPatternAttachment))
      ) {
        updateConstuctorAttachments({ valuesToUpdate, modalId });
      }

      deleteConstuctorAttachments({ modalId, valuesToUpdate: newData, state });

      const info = {
        contact_info_address: valuesToUpdate?.contact_info_address,
        main_page_banner_text: valuesToUpdate?.main_page_banner_text,
        main_page_banner_sub_text: valuesToUpdate?.main_page_banner_sub_text,
        reseller: valuesToUpdate?.reseller,
        ui_config_show_media_name: valuesToUpdate?.ui_config_show_media_name,
        ui_config_show_news_name: valuesToUpdate?.ui_config_show_news_name,
        ui_config_show_afisha_name: valuesToUpdate?.ui_config_show_afisha_name,
        ui_config_show_places_name: valuesToUpdate?.ui_config_show_places_name,
        ui_config_show_persons_name: valuesToUpdate?.ui_config_show_persons_name,
        ui_config_show_repertoire_name: valuesToUpdate?.ui_config_show_repertoire_name,
        ui_config_show_additional_name: valuesToUpdate?.ui_config_show_additional_name,
        ui_config_show_additional_name_second: valuesToUpdate?.ui_config_show_additional_name_second,
        ui_config_show_archive_name: valuesToUpdate?.ui_config_show_archive_name,
        ui_config_block_name_afisha: valuesToUpdate?.ui_config_block_name_afisha,
        ui_config_block_name_gallery: valuesToUpdate?.ui_config_block_name_gallery,
        ui_config_block_name_top_afisha: valuesToUpdate?.ui_config_block_name_top_afisha,
        ui_config_block_name_news: valuesToUpdate?.ui_config_block_name_news,
        ui_config_block_name_faq: valuesToUpdate?.ui_config_block_name_faq,
        ui_config_block_name_contact: valuesToUpdate?.ui_config_block_name_contact,
        ui_config_block_name_address: valuesToUpdate?.ui_config_block_name_address,
        ui_config_block_name_issue: valuesToUpdate?.ui_config_block_name_issue,
        ui_config_block_name_archive: valuesToUpdate?.ui_config_block_name_archive,
        ui_config_custom_pages_group_name: valuesToUpdate?.ui_config_custom_pages_group_name,
        ui_config_qna_name: valuesToUpdate?.ui_config_qna_name,
      };

      const languageExists = !!state?.data?.info.find(
        (info) => info.language_code === newData?.language_code || info.language_code === newData?.lng,
      );
      const hasInfo =
        Object.keys(info).filter((key) => info[key] !== undefined && info[key] !== null).length > 0;

      if (hasInfo) {
        if (languageExists) {
          await landingsSitesSources.updateLandingConstructorInfo({
            tech_name: modalId,
            language_code: state.lng,
            info: { language_code: state.lng, site_name: siteName, ...info },
          });
        } else {
          await landingsSitesSources.createLandingConstructorInfo({
            tech_name: modalId,
            info: { language_code: state.lng, site_name: siteName, ...info },
          });
        }
      }

      update = await api.patch(landingsSitesSources.detail(modalId), submitData);
      const promises = [update];

      return Promise.all(promises)
        .then(([updateResponse]) => {
          if (updateResponse.status === 400) {
            const errors = updateResponse.data.filter((item) => item.error);
            return errors.map((error) => {
              if (error.type === 'LANDINGS.BULK_UPDATE_FAIL') {
                return NotificationManager.error('Проверьте заполненность полей', 'Ошибка');
              }

              return NotificationManager.error('Ошибка');
            });
          }

          loadData({
            lang: LANGUAGES.RU,
            currencies: state.availableCurrencies,
          });
          return NotificationManager.success('Успешно');
        })
        .catch((err) => new Error(err));
    }

    setState((prevState) => ({
      ...prevState,
      data: currentData,
      step: prevState.step + 1,
    }));
  };

  const selectStep = (selectedStep) => {
    setState((prevState) => ({
      ...prevState,
      ...(updatedTempData && {
        data: updatedTempData,
      }),
      step: selectedStep,
    }));
  };

  return (
    <>
      <Styled.GlobalStyles />
      <StepModal.Header withMobileSteps>
        <StepModal.Title>
          Конструктор <Styled.TitleLabel>сайта</Styled.TitleLabel> {!state.isLoading && `/ ${siteName}`}
          <Styled.LangSwitcher>
            <Select small options={state.locales} value={state.lng} onChange={onChangeLng} />
          </Styled.LangSwitcher>
        </StepModal.Title>
      </StepModal.Header>
      <Styled.Main>
        <Styled.Progress>
          <ProgressBar
            isVertical
            mobileHorizontal
            steps={
              CONSTRUCTOR_MODULES[state.lng === LANGUAGES.RU ? LANGUAGES.RU : 'translations'].STEPS_WORDS
            }
            currentStep={state.step}
            onSelect={selectStep}
          />
        </Styled.Progress>
        {state.isLoading ? (
          <Spinner center size={50} />
        ) : (
          <StepComponent
            onSubmit={addData}
            goBack={goBack}
            data={state.data}
            updateData={updateTempData}
            lng={state.lng}
          />
        )}
      </Styled.Main>
    </>
  );
};

export default ConstructorModal;
