import { AxiosError } from 'axios';
import { createEffect } from 'effector';
import api from 'services/api';
import COOKIES from 'shared/constants/COOKIES';
import { PagePaginationQuery } from 'shared/factory';
import { getCookie } from 'shared/lib/cookie';
import announcementsSource from 'shared/sources/announcements';
import expansionSources from 'shared/sources/expansion';
import { companySources } from '../company';
import { eventsSources } from '../events';
import {
  AnnouncementsData,
  ICreateAnnouncementsParams,
  ICurrency,
  IExpansionParams,
  IProviderParams,
} from './types';

const companyId = getCookie(COOKIES.COMPANY);
const currencySource = 'reference/currency';

export const fetchAnnouncementsFx = createEffect<PagePaginationQuery, AnnouncementsData, AxiosError>(
  async (query) => {
    const { outdated, ...rest } = query;
    const response = await api.get(eventsSources.root, {
      params: {
        with_announcements: true,
        date_type: outdated ? 'all' : 'active',
        company_id: companyId,
        ...rest,
      },
    });

    return response.data;
  },
);

export const fetchCurrencyFx = createEffect<never, ICurrency[], AxiosError>(async () => {
  const response = await api.get(currencySource);
  const { results }: { results: ICurrency[] } = response.data.data;
  return results;
});

export const createAnnouncementsFx = createEffect<ICreateAnnouncementsParams[], void, AxiosError>(
  async (data) => {
    await api.post(announcementsSource.bulkCreate, { announcements: data });
  },
);

export const fetchProvidersFx = createEffect<never, IProviderParams[], AxiosError>(async () => {
  const response = await api.get(companySources.providers);
  const { results }: { results: IProviderParams[] } = response.data.data;

  return results;
});

export const fetchExpansionFx = createEffect<never, IExpansionParams[], AxiosError>(async () => {
  const response = await api.get(expansionSources.root);
  const { results }: { results: IExpansionParams[] } = response.data.data;

  return results;
});
