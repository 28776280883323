import dayjs, { Dayjs } from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import utc from 'dayjs/plugin/utc';
import first from 'lodash/first';
import DATE_FORMATS from '../constants/DATE_FORMATS';

dayjs.extend(utc);
dayjs.extend(isSameOrBefore);

export const isPasteDate = (date: Dayjs) => dayjs(date, DATE_FORMATS.DATE_TIME).isBefore(dayjs());

export const isNextYear = (date: string) => dayjs(date, DATE_FORMATS.DATE).isAfter(dayjs(), 'year');

export const dateWithoutOffset = (date: Date | string) => dayjs.utc(date).format();

export const combineDateAndTime = (dateDayjs, timeDayjs) => {
  const newDayjs = dateDayjs.clone();

  return newDayjs
    .hour(timeDayjs.hour())
    .minute(timeDayjs.minute())
    .second(timeDayjs.second())
    .millisecond(timeDayjs.millisecond());
};

export const generateDatesArray = (
  dateStart: dayjs.Dayjs,
  dateEnd: dayjs.Dayjs,
  times: Array<{ time_start: dayjs.Dayjs; time_end: dayjs.Dayjs }>,
) => {
  const datesArray: Array<{ startDate: dayjs.Dayjs; endDate: dayjs.Dayjs }> = [];
  const filteredTimes = times.filter(
    (o) => typeof o.time_start !== 'undefined' && typeof o.time_end !== 'undefined',
  );

  const firstDate = first<dayjs.Dayjs>(
    filteredTimes.map(({ time_start }) => combineDateAndTime(dateStart.clone(), time_start)),
  );
  const lastDate = first<dayjs.Dayjs>(
    filteredTimes.map(({ time_end }) => combineDateAndTime(dateEnd.clone(), time_end)),
  );
  let clonedStartDate = firstDate?.clone();

  while (clonedStartDate?.utc().isSameOrBefore(lastDate?.utc())) {
    filteredTimes.forEach(({ time_start, time_end }) => {
      const startDate = combineDateAndTime(clonedStartDate?.clone(), time_start);
      const endDate = combineDateAndTime(clonedStartDate?.clone(), time_end);
      datesArray.push({ startDate, endDate });
    });

    clonedStartDate = clonedStartDate.add(1, 'day');
  }

  return datesArray;
};

export const formatDatesArrayToString = (dates: string[]) => {
  if (!dates || dates.length === 0) return '';

  const parsedDates = dates.map((date) => dayjs(date)).sort((a, b) => a - b);

  if (parsedDates.length === 1) {
    return parsedDates[0].format('DD MMMM HH:mm YYYY');
  } else {
    const start = parsedDates[0];
    const end = parsedDates[parsedDates.length - 1];

    if (!start.isSame(end, 'year')) {
      return `${start.format('DD MMMM YYYY')} - ${end.format('DD MMMM YYYY')}`;
    }

    if (start.isSame(end, 'month')) {
      return `${start.format('DD')} - ${end.format('DD MMMM YYYY')}`;
    }

    return `${start.format('DD MMMM')} - ${end.format('DD MMMM YYYY')}`;
  }
};

export const formatDateFromNow = (date: string) => {
  const today = dayjs();
  const tomorrow = today.add(1, 'day');
  const receivedDate = dayjs(date);

  if (receivedDate.isSame(today, 'day')) {
    return `Сегодня ${receivedDate.format(DATE_FORMATS.TIME_PREVIEW)}`;
  }
  if (receivedDate.isSame(tomorrow, 'day')) {
    return `Завтра ${receivedDate.format(DATE_FORMATS.TIME_PREVIEW)}`;
  }

  return receivedDate.format(DATE_FORMATS.DATE_TIME_FULL);
};
