import mirrorCreator from 'mirror-creator';

export const actionTypes = mirrorCreator(
  [
    'CREATE',
    'CREATE_SUCCESS',
    'CREATE_FAIL',

    'LOAD_SALE',
    'LOAD_SALE_SUCCESS',
    'LOAD_SALE_FAIL',

    'LOAD_PURCHASES',
    'LOAD_PURCHASES_SUCCESS',
    'LOAD_PURCHASES_FAIL',

    'LOAD_PURCHASES_MORE',
    'LOAD_PURCHASES_MORE_SUCCESS',
    'LOAD_PURCHASES_MORE_FAIL',

    'FINISH_ORDER',
    'FINISH_ORDER_SUCCESS',
    'FINISH_ORDER_FAIL',

    'CANCEL_ORDER',
    'CANCEL_ORDER_SUCCESS',
    'CANCEL_ORDER_FAIL',

    'OPEN',
    'CLOSE',

    'LOAD_ORDER',
    'LOAD_ORDER_SUCCESS',
    'LOAD_ORDER_FAIL',

    'ASSIGN_USER',
    'ASSIGN_USER_SUCCESS',
    'ASSIGN_USER_FAIL',

    'ASSIGN_ME',
    'ASSIGN_ME_SUCCESS',
    'ASSIGN_ME_FAIL',

    'SET_DISCOUNT',
    'SET_DISCOUNT_SUCCESS',
    'SET_DISCOUNT_FAIL',

    'DELETE_TICKETS',
    'DELETE_TICKETS_SUCCESS',
    'DELETE_TICKETS_FAIL',

    'SET_DISCOUNT',
    'SET_DISCOUNT_SUCCESS',
    'SET_DISCOUNT_FAIL',

    'DELETE_DISCOUNT',
    'DELETE_DISCOUNT_SUCCESS',
    'DELETE_DISCOUNT_FAIL',

    'ADD_CONTEXT',
    'REMOVE_CONTEXT',

    'ADD_TICKETS_FROM_POOL',
    'ADD_TICKETS_FROM_POOL_SUCCESS',
    'ADD_TICKETS_FROM_POOL_FAIL',

    'REFUND',
    'REFUND_SUCCESS',
    'REFUND_FAIL',

    'REFUND_REQUEST',
    'REFUND_REQUEST_SUCCESS',
    'REFUND_REQUEST_FAIL',

    'SET_EXTERNAL_ID',
    'SET_EXTERNAL_ID_SUCCESS',
    'SET_EXTERNAL_ID_FAIL',

    'DELETE_EXTERNAL_ID',
    'DELETE_EXTERNAL_ID_SUCCESS',
    'DELETE_EXTERNAL_ID_FAIL',

    'BULK_REJECT',
    'BULK_REJECT_SUCCESS',
    'BULK_REJECT_FAIL',

    'BULK_ASSIGN_ME',
    'BULK_ASSIGN_ME_SUCCESS',
    'BULK_ASSIGN_ME_FAIL',

    'BULK_ASSIGN_MANAGER',
    'BULK_ASSIGN_MANAGER_SUCCESS',
    'BULK_ASSIGN_MANAGER_FAIL',

    'EDIT_USER_CUSTOMER',
    'EDIT_USER_CUSTOMER_SUCCESS',
    'EDIT_USER_CUSTOMER_FAIL',

    'LOAD_RELATED',
    'LOAD_RELATED_SUCCESS',
    'LOAD_RELATED_FAIL',

    'LOAD_CROSS',
    'LOAD_CROSS_SUCCESS',
    'LOAD_CROSS_FAIL',

    'RETURN_ORDER_ON_SALE',
    'RETURN_ORDER_ON_SALE_SUCCESS',
    'RETURN_ORDER_ON_SALE_FAIL',

    'UPDATE_FIELD',

    'LOAD_HISTORY',
    'LOAD_HISTORY_SUCCESS',
    'LOAD_HISTORY_FAIL',

    'SET_TICKET_SEARCH',
    'SET_TICKET_SEARCH_SUCCESS',
    'SET_TICKET_SEARCH_FAIL',

    'SALE_TICKETS',
    'SALE_TICKETS_SUCCESS',
    'SALE_TICKETS_FAIL',

    'LOAD_VIEW_HISTORY',
    'LOAD_VIEW_HISTORY_SUCCESS',
    'LOAD_VIEW_HISTORY_FAIL',

    'SET_SITE',
    'SET_SITE_SUCCESS',
    'SET_SITE_FAIL',

    'EDIT_OPERATOR_STAKE',
    'EDIT_OPERATOR_STAKE_SUCCESS',
    'EDIT_OPERATOR_STAKE_FAIL',

    'EDIT_OUTLAY',
    'EDIT_OUTLAY_SUCCESS',
    'EDIT_OUTLAY_FAIL',

    'SHOW_DELETE_TICKETS_FORM',
    'SHOW_DELETE_TICKETS_AND_POOL_FORM',

    'ADD_NOTIFICATION',
    'ADD_NOTIFICATION_SUCCESS',
    'ADD_NOTIFICATION_FAIL',
  ],
  {
    prefix: 'ORDER.',
  },
);
