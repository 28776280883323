import ConfirmModal from 'components/Modals/ConfirmModal';
import { useUnit } from 'effector-react';
import { useRouter } from 'next/router';
import React, { ReactNode, useEffect } from 'react';
import IDS from '../../../constants/IDS';
import preventClick from '../../../helpers/preventClick';
import { Query, replaceQuery, updateQuery } from '../../../helpers/routes';
import { useToggler, TogglerInstance } from '../../../lib/toggler';
import { useLockedBody } from '../../../lib/use-locked-body';
import { Portal } from '../../portal';
import { useEscape } from '../lib';
import { $confirmClose, closeConfirmClicked, closeModalClicked } from '../model';
import Styled from './styles';

export interface ModalProps {
  toggler: TogglerInstance;
  className?: string;
  fullSize?: boolean;
  mobileBottom?: boolean;
  children: ({
    closeModal,
    forceCloseModal,
  }: {
    closeModal: () => void;
    forceCloseModal: () => void;
  }) => ReactNode;
  id?: string | number | null;
  name?: string;
  zIndex?: number | string;
  overlayOpacity?: number;
}

let startY = 0;
let isMouseDown = false;

export const Modal: React.FC<ModalProps> = ({
  toggler,
  children,
  fullSize,
  mobileBottom = true,
  name = null,
  zIndex,
  overlayOpacity,
}) => {
  const { isOpen, close, open } = useToggler(toggler);
  const [handleCloseModalClicked, handleCloseConfirmClicked, confirmClose] = useUnit([
    closeModalClicked,
    closeConfirmClicked,
    $confirmClose,
  ]);
  const router = useRouter();

  useLockedBody(isOpen);
  useEscape(handleCloseModalClicked);

  useEffect(() => {
    const unwatchOpen = toggler.close.watch(handleCloseConfirmClicked);
    const { modalId, modalType, ...restQuery } = router.query;

    toggler.open.watch((id: any) => {
      if (!modalId && id && name) {
        updateQuery({
          modalId: id,
          modalType: name,
        });
      }
    });

    if (!isOpen && modalId && modalType === name) {
      open(modalId);
    }

    const unwatchClose = toggler.close.watch(() => {
      if (name) {
        replaceQuery(restQuery as Query);
      }
    });

    return () => {
      unwatchOpen();
      unwatchClose();
    };
  }, [router.query]);

  const handleMouseDown = (event: MouseEvent) => {
    startY = event.pageY;

    // @ts-expect-error it's ok
    if (event.nativeEvent.which === 1) {
      isMouseDown = true;
    }
  };

  const handleMouseUp = (event: MouseEvent) => {
    const diffY = event.pageY - startY;
    if (Math.abs(diffY) < 5 && isMouseDown) {
      closeModalClicked();
      isMouseDown = false;
    }

    startY = 0;
  };

  return isOpen ? (
    <Portal rootId={IDS.APP_LAYOUT}>
      <Styled.Overlay
        zIndex={zIndex}
        className="modal__overlay"
        overlayOpacity={overlayOpacity}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        popoverIsOpen={confirmClose}
        id={IDS.MODAL_CONTAINER}
      >
        <Styled.GlobalStyles />

        {confirmClose && (
          <ConfirmModal
            withPopover
            closeModal={close}
            closePopover={handleCloseConfirmClicked}
            padding="24px"
          />
        )}
        <Styled.TableContainer className="modal__table-container">
          <Styled.TableWrapper
            mobileBottom={mobileBottom}
            fullSize={fullSize}
            className="modal__table-wrapper"
          >
            <Styled.Wrapper
              className="modal__wrapper"
              onClick={preventClick}
              onMouseDown={preventClick}
              fullSize={fullSize}
            >
              {children({
                closeModal: handleCloseModalClicked,
                forceCloseModal: close,
              })}
            </Styled.Wrapper>
          </Styled.TableWrapper>
        </Styled.TableContainer>
      </Styled.Overlay>
    </Portal>
  ) : null;
};
