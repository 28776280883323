import React from 'react';
import Styled from './styles';

interface ModalCloseProps {
  onClick: () => void;
}

export const ModalClose: React.FC<ModalCloseProps> = (props) => {
  const { onClick } = props;
  return <Styled.Close onClick={onClick} className="modal__close" type="button" />;
};
