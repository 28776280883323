import React from 'react';
import { NotificationManager } from 'react-notifications';
import COOKIES from 'shared/constants/COOKIES';
import { LANGUAGES } from 'shared/constants/LANGUAGES';
import { getCookie } from 'shared/lib/cookie';
import { getAttachments } from 'shared/lib/getAttachments';
import api from 'shared/services/api';
import { createLandingAttachments } from 'shared/sources/attachments';
import landingsPersonalitiesSources from 'shared/sources/landings/landingsPersonalities';
import { IAttachmentTypes, IFormValue } from '../../../landings/types';
import PersonsForm from './PersonsForm';

interface PersonsCreateProps {
  closeModal: () => void;
  onSuccess: (data: any) => any;
  onChangeLng: () => void;
  attachmentTypes: IAttachmentTypes;
}

const PersonsCreate: React.FC<PersonsCreateProps> = (props) => {
  const { closeModal, onSuccess, onChangeLng, attachmentTypes } = props;

  const handleCreate = async (formData: IFormValue) => {
    const { ru: personData } = formData;

    const companyId = getCookie(COOKIES.COMPANY);

    const response = await api.post(landingsPersonalitiesSources.root, {
      person_id: personData.external.value,
      slug: '',
      company_id: companyId,
    });

    const attachments = getAttachments({
      data: response.data,
      formValue: formData,
      attachmentTypes,
    });

    if (attachments?.createAttachment) {
      await createLandingAttachments({ ...attachments.createAttachment, page: 'persons' });
    }

    const translations = await landingsPersonalitiesSources.createTranslation(response.data.id, {
      ...personData,
      language_code: LANGUAGES.RU,
    });

    await landingsPersonalitiesSources
      .createContentGroups(
        response.data.id,
        personData.contentGroups.map((i) => i.value),
      )
      .catch((error) => {
        if (error.response?.data?.detail === 'Already exists') {
          NotificationManager.error('Данная персона с таким тегом существует', 'Ошибка');
        }
        return error;
      });

    await landingsPersonalitiesSources.createQnA(
      response.data.id,
      (personData.qna || []).map((i) => i.value),
    );

    return translations;
  };

  return (
    <PersonsForm
      key="create"
      title="landings:personality.create_title"
      onSubmit={handleCreate}
      onSuccess={onSuccess}
      closeModal={closeModal}
      onChangeLng={onChangeLng}
      initialValues={{
        item_create: true,
      }}
    />
  );
};

export default PersonsCreate;
