import head from 'lodash/head';
import keys from 'lodash/keys';
import omit from 'lodash/omit';
import reduce from 'lodash/reduce';
import React, { Component } from 'react';
import { eventsSources } from 'entities/events';
import ANNOUNCEMENT_STEPS from 'shared/constants/ANNOUNCEMENT_STEPS';
import { getAdaptedFilterEvent } from 'shared/helpers/formatters/event';
import { convertSeats } from 'shared/helpers/seats';
import api from 'shared/services/api';
import announcementSources from 'shared/sources/announcements';
import { ModalFunctions } from '../../../../interfaces/modal';
import Modal from '../../Modal/Modal';
import AnnouncementsEdit from '../../Pages/Announcements/Forms/AnnouncementsEdit';
import AnnouncementsFirstStep from '../../Pages/Announcements/Forms/AnnouncementsFirstStep';
import AnnouncementsSecondStep from '../../Pages/Announcements/Forms/AnnouncementsSecondStep';
import ProgressBar from '../../ProgressBar';
import Spinner from '../../Spinner';
import StepModal from '../StepModal';
import Styled from './styles';

const STEP_COMPONENT = {
  [ANNOUNCEMENT_STEPS.FIRST]: AnnouncementsFirstStep,
  [ANNOUNCEMENT_STEPS.SECOND]: AnnouncementsSecondStep,
};

const STEPS_WORDS = ['Мероприятие', 'Места'];

interface ModalProps {
  data: any;
  closeModal: ModalFunctions['closeModal'];
  forceCloseModal: ModalFunctions['forceCloseModal'];
}

interface ModalState {
  step: number;
  event: any;
  isLoading: boolean;
  selectedEventId: number;
  announcementData: any;
}

class NewAnnouncementModal extends Component<ModalProps, ModalState> {
  constructor(props) {
    super(props);

    this.state = {
      step: ANNOUNCEMENT_STEPS.FIRST,
      event: null,
      isLoading: true,
      selectedEventId: null,
      announcementData: null,
    };
  }

  componentDidMount() {
    const {
      data: { initialValues },
    } = this.props;
    const { eventId, announcementId, step } = initialValues;

    let promises = [];

    if (eventId) {
      const params = { date_id_in: eventId, date_type: 'all' };

      promises = [
        ...promises,
        api.get(eventsSources.root, { params }).then((response) => {
          return {
            event: getAdaptedFilterEvent(head(response.data.results)),
          };
        }),
      ];
    }

    if (announcementId) {
      promises = [
        ...promises,
        api.get(announcementSources.detailRaw(announcementId)).then((announcement) => ({
          announcement,
        })),
      ];
    }

    Promise.all(promises)
      .then((results) => {
        const announcementData = reduce(
          results,
          (acc, item) => {
            const index = head(keys(item));

            if (item[index].data) {
              acc[index] = item[index].data;
            } else {
              acc[index] = item[index];
            }

            return acc;
          },
          {},
        );

        if (promises.length > 0) {
          const { event }: any = announcementData;

          this.setState({
            step: step || ANNOUNCEMENT_STEPS.FIRST,
            isLoading: false,
            announcementData,
            event,

            selectedEventId: eventId,
          });
        } else {
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((err) => new Error(err));
  }

  clearAnnouncementData = () => {
    this.setState({
      announcementData: null,
    });
  };

  goBack = () => {
    this.setState((prevState) => ({
      step: prevState.step - 1,
    }));
  };

  changeStep = (data) => {
    this.setState({
      step: data.step,
      ...(data.event && { event: data.event.data }),
      ...(data.event && { selectedEventId: data.event.value }),
    });
  };

  selectStep = (selectedStep) => {
    this.setState(() => ({
      step: selectedStep,
    }));
  };

  render() {
    const { step, event, selectedEventId, isLoading, announcementData } = this.state;

    const {
      data: {
        initialValues: { isEdit, currency, view_scope_type: viewScopeType, forceReload },
      },
    } = this.props;
    const StepComponent: any = STEP_COMPONENT[step];
    let initialValues;

    if (announcementData && announcementData.announcement) {
      const { data: initialData } = announcementData.announcement;

      initialValues = {
        ...omit(initialData, ['row', 'row_label', 'sector', 'sector_label', 'category', 'category_label']),
        ticket_type: initialData.ticket_type.toString(),
        announcement_type: initialData.announcement_type.toString(),
      };

      if (initialData.sector) {
        initialValues.sector = {
          label: initialData.sector_label,
          value: initialData.sector,
        };
      }

      if (initialData.row) {
        initialValues.row = {
          label: initialData.row_label,
          value: initialData.row,
        };
      }

      if (initialData.category) {
        initialValues.category = {
          label: initialData.category_label,
          value: initialData.category,
        };
      }

      if (initialData.provider) {
        initialValues.provider = {
          label: initialData.provider.name,
          value: initialData.provider.id,
        };
      }

      if (initialData.expansion) {
        initialValues.expansion = initialData.expansion.map((item) => ({
          value: item.id,
          label: item.name,
        }));
      }

      if (viewScopeType) {
        initialValues.view_scope_type = viewScopeType;
      }

      if (!head<any>(initialData.tickets).seat) {
        if (initialData.category && initialData.category) {
          initialValues.count_categories = initialData.tickets.length;
        } else {
          initialValues.count = initialData.tickets.length;
        }
      } else {
        initialValues.seat = convertSeats(initialData.tickets.map((item) => item.seat_label)).replace(
          / /g,
          '',
        );
      }

      if (currency) {
        initialValues.currency = currency;
      }
    }

    if (isLoading) {
      return (
        <Styled.SpinnerContainer>
          <Spinner />
        </Styled.SpinnerContainer>
      );
    }

    if (isEdit) {
      return (
        <AnnouncementsEdit
          event={event}
          initialValues={initialValues}
          announcementData={announcementData}
          id={this.props.data.initialValues.announcementId}
          closeModal={this.props.closeModal}
          forceCloseModal={this.props.forceCloseModal}
          forceReload={forceReload}
        />
      );
    }

    return (
      <Modal.FullSizeContainer>
        <StepModal.GlobalStyles />
        <StepModal.Header>
          <StepModal.Title>Новое объявление</StepModal.Title>
          <StepModal.ProgressBar>
            <ProgressBar steps={STEPS_WORDS} currentStep={step} onSelect={this.selectStep} createMode />
          </StepModal.ProgressBar>
        </StepModal.Header>
        <Modal.FullSizeContent>
          <StepComponent
            event={event}
            goBack={this.goBack}
            changeStep={this.changeStep}
            initialValues={initialValues}
            selectedEventId={selectedEventId}
            announcementData={announcementData}
            clearAnnouncementData={this.clearAnnouncementData}
          />
        </Modal.FullSizeContent>
      </Modal.FullSizeContainer>
    );
  }
}

export default NewAnnouncementModal;
