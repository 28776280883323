import ConfirmModal from 'components/Modals/ConfirmModal';
import Spinner from 'components/Spinner';
import isArray from 'lodash/isArray';
import React, { useState, useEffect, useRef } from 'react';
import { NotificationManager } from 'react-notifications';
import { useDispatch } from 'react-redux';
import { changeModalState, openModal } from 'entities/modal';
import { LANGUAGES } from 'shared/constants/LANGUAGES';
import LOCALES from 'shared/constants/LOCALES';
import MODAL_STATES from 'shared/constants/MODAL_STATES';
import MODALS from 'shared/constants/MODALS';
import PAGES from 'shared/constants/PAGES';
import useLandings from 'shared/lib/useLandings';
import useListItem from 'shared/lib/useListItem';
import { ModalFunctions } from '../../../../interfaces/modal';
import { i18n, useTranslation } from '../../../i18n';

interface Props {
  data: any;
  modalState?: any;
  closeModal: ModalFunctions['closeModal'];
  forceCloseModal: ModalFunctions['forceCloseModal'];
  page?: string;
  translation?: string;
  v2: boolean;
}

function withLandingData(
  WrappedComponent: any,
  { sources, translation, params = {}, page, mappedCreateData, mappedUpdateData, newDetailApi }: any,
) {
  const LandingData = (props: Props) => {
    const { modalState, forceCloseModal } = props;
    const dispatch = useDispatch();
    const { t } = useTranslation([LOCALES.LANDINGS, LOCALES.FORMS]);
    const { get, remove, emitListReload, onListItemUpdate } = useListItem({
      source: sources,
      newDetailApi,
    });
    const { create, update } = useLandings({
      source: sources,
      mappedCreateData,
      mappedUpdateData,
      query: params,
      page,
    });
    const [langLoading, setLangLoading] = useState<boolean>(false);
    const [state, setState] = useState<any>({
      data: props.data,
      showDeletePopover: false,
      isEmpty: true,
      lng: i18n.language || 'ru',
    });
    const mounted = useRef(true);

    const loadData = (lng = 'ru') => {
      get(props.data?.id, lng, params)
        .then((data) => {
          const is404 = data.status === 404;

          if (is404 && data.lng === LANGUAGES.RU) {
            NotificationManager.error('Ошибка');
            forceCloseModal();
            return;
          }

          setState((prevState) => ({
            ...prevState,
            data: is404 ? { create: true, id: props.data.id } : { ...data, id: props.data?.id },
            isLoading: false,
            isEmpty: false,
            lng,
          }));
          setLangLoading(false);
        })
        .catch((err) => new Error(err));
    };

    useEffect(() => {
      if (modalState !== MODAL_STATES.CREATE) {
        loadData();
      } else {
        setState((prevState) => ({ ...prevState, isLoading: false }));
      }

      return () => {
        mounted.current = false;
      };
    }, []);

    const onChangeLng = (lng) => {
      if (modalState !== MODAL_STATES.CREATE) {
        setLangLoading(true);
        loadData(lng);
      }
    };

    const openEditView = () => {
      const modalOverlay = document.getElementById('modal_container');

      dispatch(changeModalState(MODAL_STATES.EDIT));
      modalOverlay.scrollTop = 0;
    };

    const hideDeleteModal = () => {
      if (mounted.current) {
        setState((prevState) => ({
          ...prevState,
          showDeletePopover: false,
        }));
      }
    };

    const showDeleteModal = () => {
      if (mounted.current) {
        setState((prevState) => ({
          ...prevState,
          showDeletePopover: true,
        }));
      }
    };

    const deleteItem = () =>
      remove({ id: state.data.id, params }).then((response) => {
        forceCloseModal();
        if (
          isArray(response.error) &&
          response.error[0] === 'You can not delete the object.' &&
          page === PAGES.LANDING_TEMPLATE
        ) {
          return NotificationManager.error('Удаление невозможно. Данный шаблон используется.', 'Ошибка');
        }

        if (response.blocked_by_elements && page === PAGES.LANDING_TAGS) {
          return dispatch(
            openModal(MODALS.LANDINGS_ERRORS_MODAL, {
              errors: response.blocked_by_elements,
              name: state.data.name,
              page: PAGES.LANDING_TAGS,
            }),
          );
        }

        if (response.blocked_by_elements && page === PAGES.LANDING_PERSONALITIES) {
          return dispatch(
            openModal(MODALS.LANDINGS_ERRORS_MODAL, {
              errors: response.blocked_by_elements,
              name: state.data.title,
              page: PAGES.LANDING_PERSONALITIES,
            }),
          );
        }

        emitListReload();
        return true;
      });

    const updateList = () => {
      emitListReload();
      props.forceCloseModal();
    };

    const onUpdate = (updatedData) => {
      if (updatedData.ru) {
        onListItemUpdate(updatedData.ru);
      }

      props.forceCloseModal();
    };

    return (
      <>
        {modalState !== MODAL_STATES.CREATE && state.isEmpty ? (
          <Spinner size={50} center />
        ) : (
          <WrappedComponent
            {...props}
            langLoading={langLoading}
            onChangeLng={onChangeLng}
            onListItemUpdate={onListItemUpdate}
            updateList={updateList}
            data={state.data}
            lng={state.lng}
            showDeleteModal={showDeleteModal}
            openEditView={openEditView}
            onUpdate={onUpdate}
            create={create}
            update={update}
            t={t}
          />
        )}

        {state.showDeletePopover && (
          <ConfirmModal
            withPopover
            data={{
              title: t(`landings:${translation}.delete_title`),
              text: t(`landings:${translation}.delete_text`),
              onSubmit: deleteItem,
            }}
            closeModal={hideDeleteModal}
            padding="24px"
          />
        )}
      </>
    );
  };

  LandingData.defaultProps = {
    modalState: null,
    page: null,
    translation: 'common',
  };

  return LandingData;
}

export default withLandingData;
