import { AxiosError } from 'axios';
import { isEqual } from 'lodash';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { orderSources } from 'entities/order';
import api from 'shared/services/api';
import SidebarLink from './SidebarLink';
import { SidebarLinkProps } from './SidebarLink/SidebarLink';

interface Props extends SidebarLinkProps {
  sales: { [key: number]: any };
}

const SidebarSales: React.FC<Props> = (props) => {
  const [state, setState] = useState({
    count: 0,
    sales: {},
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadCount = async () => {
      setIsLoading(true);
      try {
        const response = await api.get(orderSources.root, { params: { status_in: 1 } });

        setState({ count: response.data.count, sales: props.sales });
      } catch (err) {
        throw err as AxiosError;
      } finally {
        setIsLoading(false);
      }
    };

    if (!isLoading && !isEqual(state.sales, props.sales)) {
      loadCount();
    }
  }, [props.sales]);

  return <SidebarLink {...props} withNewLabel={Boolean(state.count)} />;
};

const mapDispatchToProps = (state: any) => ({
  sales: state.sales.data,
});

export default connect(mapDispatchToProps)(SidebarSales);
