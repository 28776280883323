import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'next-i18next';
import React, { useRef } from 'react';
import { Field, FormSpy } from 'react-final-form';
import { useSelector } from 'react-redux';
import Button from 'components/Button';
import { LangForm } from 'components/Form';
import {
  Input,
  FileCropperInput,
  InputWithSelect,
  FormDescription,
  SmallFormDescription,
  FormControl,
  FileArrayInput,
  LangSwitcher,
} from 'components/FormControls';
import Modal from 'components/Modal';
import StepModal from 'components/Modals/StepModal';
import LandingsModalLayout from 'containers/Pages/Landings/landingsModalLayout';
import { Editor } from 'entities/editor';
import { tagsRoute } from 'shared/api/landings/tags';
import { referencePersonsSources } from 'shared/api/reference/persons';
import { AttachmentType } from 'shared/constants/ATTACHMENTS_TYPE';
import {
  STEPS_PREVIEW,
  ASPECT_RATIO_PREVIEW,
  STEPS_WORDS_PREVIEW,
  SIZES_PREVIEW,
  LANDINGS_IMAGE_SIZES,
  LANDINGS_PREVIEW_IMAGE_SIZES,
} from 'shared/constants/IMAGE_CONFIG';
import { LANGUAGES } from 'shared/constants/LANGUAGES';
import { renderContentGroups, renderReferencePersons } from 'shared/helpers/optionsRenderers';
import {
  composeValidators,
  required,
  maxValue,
  landingDescriptionValidation,
} from 'shared/helpers/validations';
import withCheckIsMobile from 'shared/lib/withCheckIsMobile';
import qna from 'shared/sources/reference/qna';
import { ModalFunctions } from '../../../../../interfaces/modal';
import PlusIcon from '../../../../../static/icons/close.svg';
import { selectAccount } from '../../../../entities/account/reducer';
import InputStyled from '../../events/EventsModal/styled';

interface FormProps {
  title: string;
  closeModal: ModalFunctions['closeModal'];
  isMobile: boolean;
  isDesktop: boolean;
  onChangeLng: any;
  isEdit?: boolean;
  initialValues?: any;
  children?: any;
  lng: string;
}

let finalForm;

const PersonsForm: React.FC<FormProps> = ({
  children,
  title,
  closeModal,
  isMobile,
  isDesktop,
  onChangeLng,
  isEdit,
  lng,
  ...rest
}) => {
  const { t } = useTranslation();
  const account = useSelector(selectAccount);

  const resetAttachmentInput = (attachmentCode) => {
    finalForm.batch(() => {
      finalForm.change(`${lng}.${attachmentCode}_title`, '');
      finalForm.change(`${lng}.${attachmentCode}_alt`, '');
    });
  };

  const currentValues = useRef({});

  const handleChange = (selectedLang: string) => {
    onChangeLng(selectedLang);
    currentValues.current = {};
  };

  return (
    <LangForm
      {...rest}
      onChangeLng={handleChange}
      stashLanguages={!isEdit}
      initialValues={{
        ...rest.initialValues,
        ...currentValues.current[lng],
        company_id: account.company.id,
      }}
      render={({ handleSubmit, form, i18n, switcher, values }) => {
        finalForm = form;
        const disabledByLanguage = i18n.language !== LANGUAGES.RU;

        const hasValueMainPage = !isEmpty(values[i18n.language]?.main_image);
        const hasValueCover = !isEmpty(values[i18n.language]?.cover);

        return (
          <form onSubmit={handleSubmit}>
            <FormSpy
              subscription={{ values: true }}
              onChange={(changeProps: any) => {
                currentValues.current[i18n.language] = changeProps.values[i18n.language];
              }}
            />
            <LandingsModalLayout.GlobalStyles />
            <Modal.FullSizeContainer>
              <StepModal.Header>
                <StepModal.WithLanguage>
                  <StepModal.Title>{t(title)}</StepModal.Title>
                  {isDesktop && <LangSwitcher />}
                </StepModal.WithLanguage>
              </StepModal.Header>
              <Modal.FullSizeContent>
                <Modal.Container>
                  <Field name={`${i18n.language}.id`} component="input" type="hidden" />
                  <Modal.Section>
                    {!isDesktop && <LandingsModalLayout.Row>{switcher}</LandingsModalLayout.Row>}
                    {!disabledByLanguage && (
                      <LandingsModalLayout.Row grid>
                        <FormDescription width={208}>Персональ из справочников</FormDescription>
                        <LandingsModalLayout.Column>
                          <FormControl>
                            <Field name={`${i18n.language}.external`} validate={isEdit ? null : required}>
                              {({ input, meta }) => (
                                <InputWithSelect
                                  isAsync
                                  disabled={isEdit}
                                  placeholder={t('landings:personality.person')}
                                  route={referencePersonsSources}
                                  searchQueryName="search_string"
                                  optionsRenderer={renderReferencePersons}
                                  query={{
                                    language_code: i18n?.language || LANGUAGES.RU,
                                  }}
                                  meta={meta}
                                  {...input}
                                />
                              )}
                            </Field>
                          </FormControl>
                        </LandingsModalLayout.Column>
                      </LandingsModalLayout.Row>
                    )}
                  </Modal.Section>
                  <Modal.Section>
                    <LandingsModalLayout.Row grid>
                      <FormDescription width={208}>{t('landings:personality.name')}</FormDescription>
                      <LandingsModalLayout.Column>
                        <FormControl>
                          <Field
                            name={`${i18n.language}.title`}
                            validate={composeValidators(required, maxValue(255))}
                          >
                            {({ input, meta }) => (
                              <Input
                                label={t('landings:personality.person')}
                                autoFocus
                                {...input}
                                meta={meta}
                              />
                            )}
                          </Field>
                        </FormControl>
                      </LandingsModalLayout.Column>
                    </LandingsModalLayout.Row>
                  </Modal.Section>

                  {!disabledByLanguage ? (
                    <>
                      <Modal.Section>
                        <LandingsModalLayout.Row grid>
                          <FormDescription width={208}>{t('landings:personality.tags')}</FormDescription>
                          <FormControl>
                            <Field name={`${i18n.language}.contentGroups`} validate={required}>
                              {({ input, meta }) => (
                                <InputWithSelect
                                  isAsync
                                  isMulti
                                  isCreatable
                                  searchQueryName="search_string"
                                  label={t('landings:personality.tags')}
                                  optionsRenderer={renderContentGroups}
                                  route={{ root: tagsRoute }}
                                  meta={meta}
                                  {...input}
                                />
                              )}
                            </Field>
                          </FormControl>
                        </LandingsModalLayout.Row>
                      </Modal.Section>
                      <Modal.Section>
                        <LandingsModalLayout.Row grid>
                          <FormDescription width={208}>{t('landings:personality.qna')}</FormDescription>
                          <FormControl>
                            <Field name={`${i18n.language}.qna`}>
                              {({ input, meta }) => (
                                <InputWithSelect
                                  isAsync
                                  isMulti
                                  searchQueryName="search_string"
                                  label={t('landings:personality.qna')}
                                  optionsRenderer={renderContentGroups}
                                  route={qna}
                                  meta={meta}
                                  {...input}
                                />
                              )}
                            </Field>
                          </FormControl>
                        </LandingsModalLayout.Row>
                      </Modal.Section>
                      <Modal.Section>
                        <LandingsModalLayout.Row grid>
                          <FormDescription width={208}>
                            {t('landings:personality.images')}
                            <SmallFormDescription>
                              {t('landings:personality.images_description')}
                            </SmallFormDescription>
                          </FormDescription>
                          <FormControl column>
                            <LandingsModalLayout.Row>
                              <Field name={`${i18n.language}.main_image`}>
                                {({ input, meta }) => (
                                  <FileCropperInput
                                    placeholder={t('landings:personality.upload_cover')}
                                    // eslint-disable-next-line
                                    description={t('landings:personality.upload_cover_description')}
                                    {...input}
                                    meta={meta}
                                    imageSizes={LANDINGS_IMAGE_SIZES}
                                    attachmentCode={AttachmentType.Main_Image}
                                    resetAttachmentForm={resetAttachmentInput}
                                  />
                                )}
                              </Field>
                              {hasValueMainPage && (
                                <>
                                  <InputStyled.InputWrapper auto>
                                    <Field name={`${i18n.language}.main_image_title`}>
                                      {({ input, meta }) => (
                                        <Input label={t('forms:cover_title')} {...input} meta={meta} />
                                      )}
                                    </Field>
                                  </InputStyled.InputWrapper>
                                  <InputStyled.InputWrapper auto>
                                    <Field name={`${i18n.language}.main_image_alt`}>
                                      {({ input, meta }) => (
                                        <Input label={t('forms:cover_alt')} {...input} meta={meta} />
                                      )}
                                    </Field>
                                  </InputStyled.InputWrapper>
                                </>
                              )}
                            </LandingsModalLayout.Row>
                            <LandingsModalLayout.Row>
                              <Field name={`${i18n.language}.cover`}>
                                {({ input, meta }) => (
                                  <FileCropperInput
                                    placeholder={t('landings:personality.upload_preview')}
                                    description={t('landings:personality.upload_preview_description')}
                                    {...input}
                                    meta={meta}
                                    stepsEnum={STEPS_PREVIEW}
                                    ratio={ASPECT_RATIO_PREVIEW}
                                    words={STEPS_WORDS_PREVIEW}
                                    sizes={SIZES_PREVIEW}
                                    imageSizes={LANDINGS_PREVIEW_IMAGE_SIZES}
                                    attachmentCode={AttachmentType.Cover}
                                    resetAttachmentForm={resetAttachmentInput}
                                  />
                                )}
                              </Field>
                              {hasValueCover && (
                                <>
                                  <InputStyled.InputWrapper auto>
                                    <Field name={`${i18n.language}.cover_title`}>
                                      {({ input, meta }) => (
                                        <Input label={t('forms:preview_title')} {...input} meta={meta} />
                                      )}
                                    </Field>
                                  </InputStyled.InputWrapper>
                                  <InputStyled.InputWrapper auto>
                                    <Field name={`${i18n.language}.cover_alt`}>
                                      {({ input, meta }) => (
                                        <Input label={t('forms:preview_alt')} {...input} meta={meta} />
                                      )}
                                    </Field>
                                  </InputStyled.InputWrapper>
                                </>
                              )}
                            </LandingsModalLayout.Row>
                          </FormControl>
                        </LandingsModalLayout.Row>
                      </Modal.Section>

                      <Modal.Section>
                        <LandingsModalLayout.Row grid>
                          <FormDescription width={208}>
                            {t('landings:personality.gallery')}
                            <SmallFormDescription>
                              {t('landings:personality.gallery_description')}
                            </SmallFormDescription>
                          </FormDescription>
                          <FormControl column>
                            <LandingsModalLayout.Row>
                              <Field name={`${i18n.language}.gallery`}>
                                {({ input, meta }) => <FileArrayInput {...input} meta={meta} />}
                              </Field>
                            </LandingsModalLayout.Row>
                          </FormControl>
                        </LandingsModalLayout.Row>
                      </Modal.Section>
                    </>
                  ) : (
                    <>
                      {(hasValueCover || hasValueMainPage) && (
                        <Modal.Section>
                          <LandingsModalLayout.Row grid>
                            <FormDescription width={208}>
                              {t('landings:personality.images')}
                              <SmallFormDescription>
                                {t('landings:personality.images_description')}
                              </SmallFormDescription>
                            </FormDescription>
                            <FormControl column>
                              {hasValueMainPage && (
                                <LandingsModalLayout.Row>
                                  <InputStyled.InputWrapper>
                                    <Field name={`${i18n.language}.main_image_title`}>
                                      {({ input, meta }) => (
                                        <Input label={t('forms:cover_title')} {...input} meta={meta} />
                                      )}
                                    </Field>
                                  </InputStyled.InputWrapper>
                                  <InputStyled.InputWrapper auto>
                                    <Field name={`${i18n.language}.main_image_alt`}>
                                      {({ input, meta }) => (
                                        <Input label={t('forms:cover_alt')} {...input} meta={meta} />
                                      )}
                                    </Field>
                                  </InputStyled.InputWrapper>
                                </LandingsModalLayout.Row>
                              )}

                              {hasValueCover && (
                                <LandingsModalLayout.Row>
                                  <InputStyled.InputWrapper auto>
                                    <Field name={`${i18n.language}.cover_title`}>
                                      {({ input, meta }) => (
                                        <Input label={t('forms:preview_title')} {...input} meta={meta} />
                                      )}
                                    </Field>
                                  </InputStyled.InputWrapper>
                                  <InputStyled.InputWrapper auto>
                                    <Field name={`${i18n.language}.cover_alt`}>
                                      {({ input, meta }) => (
                                        <Input label={t('forms:preview_alt')} {...input} meta={meta} />
                                      )}
                                    </Field>
                                  </InputStyled.InputWrapper>
                                </LandingsModalLayout.Row>
                              )}
                            </FormControl>
                          </LandingsModalLayout.Row>
                        </Modal.Section>
                      )}
                    </>
                  )}

                  <Modal.Editor>
                    <Field name={`${i18n.language}.description`} validate={landingDescriptionValidation}>
                      {({ input, meta }) => (
                        <Editor
                          id="person_1"
                          label="Описание"
                          key={rest.initialValues?.description || i18n.language}
                          inModal
                          input={input}
                          meta={meta}
                        />
                      )}
                    </Field>
                  </Modal.Editor>
                </Modal.Container>

                {!children ? (
                  <Modal.Footer justify fullSize contentWidth="800px">
                    <Button type="button" transparent onlyIcon={isMobile} onClick={closeModal}>
                      {isMobile ? <PlusIcon /> : t('forms:cancel')}
                    </Button>
                    <Button
                      onClick={() => {
                        form.change('action', 'create');
                      }}
                    >
                      {t('forms:create')}
                    </Button>
                  </Modal.Footer>
                ) : (
                  <Modal.Footer fullSize contentWidth="800px">
                    {children}
                  </Modal.Footer>
                )}
              </Modal.FullSizeContent>
            </Modal.FullSizeContainer>
          </form>
        );
      }}
    />
  );
};

export default withCheckIsMobile(PersonsForm);
